export const Home = '/';
export const Login = '/login';
export const Authenticate = '/authenticate';
export const Logout = '/logout';

export const Forms = '/_f';
export const Redirect = '/_r';
export const Download = '/_dl'; 
export const AppointmentConfirming = '/_ap';
export const AppointmentConfirmed = '/_appointment-confirmed';
export const AppointmentConfirmError = '/_appointment-confirm-error';
export const ReviewRequest = '/_review';
export const ReviewFeedback = '/_feedback';
export const PageNotFound = '/_404';

export const Appointments = {
    Home: '/appointments',
}
export const Customers = {
    Home: '/customers',
}
export const Exams = {
    List: '/exams',
    View: '/exam',
}

export const Purchases = {
    Home: '/purchases',
    Invoices: '/purchases/invoices',
    Sales: '/purchases/sales',
    Payments: '/purchases/payments',
}

export const Quotes = {
    Home: '/quotes',
}
export const WorkOrders = {
    List: '/work-orders',
    View: '/work-order',
}
export const Schedules = {
    Home: '/schedules',
}
export const OnlineBookingSchedules = {
    Home: '/online-booking-schedules',
}
export const Products = {
    Home: '/products',
}
export const Services = {
    Home: '/services',
}
export const PublicInsuranceBillingReport = {
    Home: '/public-insurances',
}
export const Referrals = {
    Home: '/referrals',
}
export const SearchResults = {
    Home: '/search',
}
export const Settings = {
    UserAccount: '/settings/user-account',
    ExamTemplate: '/settings/exam/templates',
    ExamInput: '/settings/exam/inputs',
    ExamDataset: '/settings/exam/datasets',
    WorkOrderTemplate: '/settings/work-order/templates',
    WorkOrderInput: '/settings/work-order/inputs',
    PrescriptionPrintTemplate: '/settings/prescription-print/templates',
    PrescriptionTemplate: '/settings/prescription/templates',
    PrescriptionInput: '/settings/prescription/inputs',
    PretestTemplate: '/settings/pretest/templates',
    PretestInput: '/settings/pretest/inputs',
    PretestLogicalDevices: '/settings/pretest/logical-devices',
    PretestDeviceSyncDownload: '/settings/pretest/device-sync-download',
    FaxCoverPageTemplate: '/settings/fax-cover-page/template',
    InvoiceTemplate: '/settings/invoice/template',
    PaymentReceiptTemplate: '/settings/payment-receipt/template',
    FormTemplate: '/settings/form/templates',
    InternalFormTemplate: '/settings/internal-form/templates',
    InternalFormInput: '/settings/internal-form/inputs',
    ReferralTemplate: '/settings/referral/template',
    ReferralTenantExamDatasetGroup: '/settings/referral/exam-dataset-groups',
    MedicalReportTemplate: '/settings/medical-report/template',
    MedicalReportTenantExamDatasetGroup: '/settings/medical-report/exam-dataset-groups',
    CommunicationTemplate: '/settings/communication/templates',
}
export const Subscription = {
    Plans: '/subscription/account',
    CheckoutStatus: '/subscription/checkout-status'
}
export const TenantManagement = {
    Home: '/organization/tenants'
}
export const SwitchTenant = {
    Home: '/switch-tenant'
}

export const OrganizationManagement = {
    Home: '/organization/organizations'
}
export const UserManagement = {
    Home: '/organization/user-management',
    Users: '/organization/user-management/users', 
    Groups: '/organization/user-management/groups'
}
export const SecuredDownload = {
    Home: '/secured/download',
    Popup: '/secured/download/popup',
}
export const Tasks = {
    Home: '/tasks',
}
export const Notifications = {
    Home: '/notifications',
}
export const Communications = {
    Home: '/communications',
    Scheduled: '/communications/scheduled',
    Failed: '/communications/failed',
    Sent: '/communications/sent',
}
