export const PRE_BETA_RELEASE = '__PRE_BETA_RELEASE__';

export const MCEDT_NOTIFICATION_USER = '__MCEDT_NOTIFICATION_USER__';
export const MCEDT_NOTIFICATION_GROUP = '__MCEDT_NOTIFICATION_GROUP__';

export const PHONE_NUMBER_FORMAT = '__PHONE_NUMBER_FORMAT__';
export const LONG_DATE_FORMAT = '__LONG_DATE_FORMAT__';
export const SHORT_DATE_FORMAT = '__SHORT_DATE_FORMAT__';
export const SPOKEN_LANGUAGES = '__SPOKEN_LANGUAGES__';
export const COMMUNICATION_LANGUAGES = '__COMMUNICATION_LANGUAGES__';
export const GOVERNMENT_PROGRAMS = '__GOVERNMENT_PROGRAMS__';
export const APPOINTMENTS_DAYVIEW_GOTO_OPTIONS = '__APPOINTMENTS_DAYVIEW_GOTO_OPTIONS__';
export const APPOINTMENTS_WEEKVIEW_GOTO_OPTIONS = '__APPOINTMENTS_WEEKVIEW_GOTO_OPTIONS__';

export const DAYS_NOTIFY_EXAM_INCOMPLETE = '__DAYS_NOTIFY_EXAM_INCOMPLETE__';

export const COMMUNICATION_NOTIFICATION_USER = '__COMMUNICATION_NOTIFICATION_USER__';
export const COMMUNICATION_NOTIFICATION_GROUP = '__COMMUNICATION_NOTIFICATION_GROUP__';

export const RECALL_MAX_ATTEMPTS = '__RECALL_MAX_ATTEMPTS__';

export const PURCHASE_UNIT_PRICE_UPDATE_ENABLED = '__PURCHASE_UNIT_PRICE_UPDATE_ENABLED__';

export const PAPER_MIGRATION_ENABLED = '__PAPER_MIGRATION_ENABLED__';
export const PRESCRIPTION_DRAFT_PRINT_ENABLED = '__PRESCRIPTION_DRAFT_PRINT_ENABLED__';

export const GROUP_APPOINTMENTS_ENABLED = '__GROUP_APPOINTMENTS_ENABLED__';

export const RECALL_MODE = '__RECALL_MODE__';
export const RECALL_YEARS_IN_REAR = '__RECALL_YEARS_IN_REAR__';
export const RECALL_DAYS_IN_ADVANCED = '__RECALL_DAYS_IN_ADVANCED__';
export const RECALL_SEND_DELAY = '__RECALL_SEND_DELAY__';
export const RECALL_SEND_TIME = '__RECALL_SEND_TIME__';

export const REVIEW_MODE = '__REVIEW_MODE__';
export const REVIEW_URL = '__REVIEW_URL__';
export const REVIEW_SEND_DELAY = '__REVIEW_SEND_DELAY__';
export const REVIEW_SEND_TIME = '__REVIEW_SEND_TIME__';
export const REVIEW_SEND_MULTIPLE_PER_PATIENT = '__REVIEW_SEND_MULTIPLE_PER_PATIENT__';

export const END_OF_DAY_ENABLED = '__END_OF_DAY_ENABLED__';
export const END_OF_DAY_SUMMARY_SEND_TO = '__END_OF_DAY_SUMMARY_SEND_TO__';
export const END_OF_DAY_ASSOCIATE_SUMMARY_SEND_TO = '__END_OF_DAY_ASSOCIATE_SUMMARY_SEND_TO__';

export const ONLINE_BOOKING_ENABLED = '__ONLINE_BOOKING_ENABLED__';
export const ONLINE_BOOKING_GOOGLE_PLACE_ID = '__ONLINE_BOOKING_GOOGLE_PLACE_ID__';