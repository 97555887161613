import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';
import moment from 'moment';

import * as fn from '../utilities/_functions';
import api from '../api';

export class TimeslotScheduleUpdate {
    id = null;
    data = null;
    hasUnsavedChanges = false;
    isLoading = false;
    isSaving = false;
    isReady = false;
    cancelTimeslotScheduleGet = null;
    cancelTimeslotScheduleDelete = null;
    cancelTimeslotScheduleUpdate = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;
        this.isReady = false;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    set = (timeslotSchedule) => {
        this.clear();
        this.id = timeslotSchedule.id;
        this.data = toJS(timeslotSchedule);
        this.isReady = true;

        return Promise.resolve();
    }

    refresh = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            that.isLoading = true;
            api.TimeslotSchedules.get(
                that.id,
                (c) => { that.cancelTimeslotScheduleGet = c; }
            )
                .then(({ data }) => {
                    that.data = data;
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (that.hasUnsavedChanges) {
                api.TimeslotSchedules.update(
                    that.id,
                    {
                        start: that.data.start,
                        end: that.data.end,
                        bookingTypeIds: that.data.bookingTypes.map((a) => { return a.id }),
                    },
                    (c) => { this.cancelTimeslotScheduleUpdate = c; }
                )
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                that.isSaving = false;
                that.hasUnsavedChanges = false;
                resolve();
            }
        })
    }

    delete = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.TimeslotSchedules.delete(
                that.id,
                (c) => { this.cancelTimeslotScheduleDelete = c; }
            )
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.hasUnsavedChanges = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelTimeslotScheduleGet)) {
            this.cancelTimeslotScheduleGet();
            this.cancelTimeslotScheduleGet = null;
        }

        if (fn.isFunction(this.cancelTimeslotScheduleDelete)) {
            this.cancelTimeslotScheduleDelete();
            this.cancelTimeslotScheduleDelete = null;
        }

        if (fn.isFunction(this.cancelTimeslotScheduleUpdate)) {
            this.cancelTimeslotScheduleUpdate();
            this.cancelTimeslotScheduleUpdate = null;
        }
    }

    get start() {
        if (!this.data) return null;
        return moment(this.data.startDateTime);
    }

    get end() {
        if (!this.data) return null;
        return moment(this.data.endDateTime);
    }

    get recommendedDuration() {
        return this.data.services.map(s => { return (s.duration ? s.duration : 0) }).reduce((a, b) => a + b, 0);
    }
}

decorate(TimeslotScheduleUpdate, {
    id: observable,
    data: observable,
    hasUnsavedChanges: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    set: action,
    refresh: action,
    save: action,
    delete: action,
    clear: action,
    start: computed,
    end: computed,
    recommendedDuration: computed,
})

export default createContext(new TimeslotScheduleUpdate());