import { createContext } from 'react';
import { decorate, observable, action, computed, toJS } from 'mobx';
import moment from 'moment';

import * as fn from '../utilities/_functions';
import * as ah from '../utilities/appointmentHelper';

import api from '../api';

export class AppointmentOfferCreate {
    id = null;
    data = {
        timeslots: [],
        items: [],
        primaryContactId: null,
        primaryContact: null,
        duration: null,
        question: null,
        notes: [],
    };
    conflicts = [];
    url = null;
    isInSelectionMode = false;
    checkUpcomingConflict = true;
    isReady = false;
    hasUnsavedChanges = false;
    isSaving = false;
    cancelAppointmentOfferCreate = null;

    initialize = (option) => {
        this.clear();

        if (option) {
            const { appointment, groupAppointment } = option;

            if (appointment) {
                this.addNewItem();

                const start = appointment.start;
                const userId = appointment.userId;

                this.addNewTimeslot(start, userId);
                this.data.items[0].customerId = appointment.customerId;
                this.data.items[0].customer = appointment.customer;
                this.data.items[0].relationship = null;
                this.data.items[0].services = appointment.services;
                this.data.duration = this.recommendedDuration;
                this.data.primaryContact = appointment.customer && appointment.customer.primaryContactPerson ? appointment.customer.primaryContactPerson : appointment.customer;
                this.data.primaryContactId = this.data.primaryContact ? this.data.primaryContact.id : null;
                this.hasUnsavedChanges = true;
            }
            else if (groupAppointment && groupAppointment.items && groupAppointment.items.length > 0) {
                const groupStart = groupAppointment.start;
                const groupUserId = groupAppointment.userId;

                this.addNewTimeslot(groupStart, groupUserId);
                this.data.items = groupAppointment.items && groupAppointment.items.length > 0 ? groupAppointment.items : [];
                this.data.primaryContact = groupAppointment.primaryContact;
                this.data.primaryContactId = groupAppointment.primaryContactId;
                this.data.duration = this.recommendedDuration;
                this.hasUnsavedChanges = true;
            }
            else {
                this.addNewItem();
            }
        }
        else {
            this.addNewItem();
        }

        this.isReady = true;
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            let option = toJS(that.data);

            option.timeslots = that.data.timeslots.map(s => {
                return {
                    userId: s.userId,
                    start: s.start.clone().format('YYYY-MM-DDTHH:mm'),
                    end: s.start.clone().add(that.data.duration, 'minutes').format('YYYY-MM-DDTHH:mm')
                }
            });

            if (that.hasUnsavedChanges) {
                api.AppointmentOffers.create(
                    option,
                    (c) => { that.cancelAppointmentOfferCreate = c; },
                )
                    .then(({ data }) => {
                        that.id = data.id;
                        that.url = data.url;
                        option.id = data.id;
                        option.url = data.url;
                        option.isAppointmentOffer = true;
                        resolve(option);
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                        that.hasUnsavedChanges = false;
                    })
            } else {
                this.isSaving = false;
                that.hasUnsavedChanges = false;
                resolve(option);
            }
        })
    }

    checkConflicts = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            that.conflicts.clear();

            if (that.isReady && that.data.items && that.data.items.length > 0 && that.data.timeslots && that.data.timeslots.length > 0) {
                const options = {
                    appointments: that.data.timeslots.map(t => {
                        return {
                            customerIds: that.data.items.filter(c => !!c.customerId).map(c => { return c.customerId }),
                            start: moment(t.start).format('YYYY-MM-DDTHH:mm'),
                            end: moment(t.start).clone().add((that.data.duration ? that.data.duration : 5), 'minutes').format('YYYY-MM-DDTHH:mm'),
                            userId: t.userId,
                            checkUpcoming: that.checkUpcomingConflict,
                        }
                    })
                };

                api.Appointments.conflict(options)
                    .then(({ data }) => {
                        that.conflicts = data && data.length > 0 ? data : [];
                        resolve(data);
                    })
                    .catch(error => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else {
                resolve();
            }
        })
    }

    checkEligibility = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            const start = that.data.timeslots && that.data.timeslots.length > 0 ? that.data.timeslots.slice().sort((a, b) => { return a.start.toDate().getTime() - b.start.toDate().getTime() })[0].start : moment();
            const options = that.data.items.filter(c => !!c.customer && !!c.services && c.services.length > 0).map(c => {
                return {
                    customer: c.customer,
                    data: {
                        start: start,
                        services: c.services
                    }
                }
            })

            if (options && options.length > 0) {
                ah.checkEligibilityForGroup(options)
                    .then((data) => {
                        if (data && data.length > 0) {
                            for (let di = 0; di < data.length; di++) {
                                if (data[di].service && data[di].service.isSubsidized) {
                                    const ci = that.data.items.findIndex(c => !!c.customer && !!data[di].customer && c.customer.id === data[di].customer.id && c.services && c.services.length > 0);

                                    if (ci > -1) {
                                        const ii = that.data.items[ci].services.findIndex(s => s.code === data[di].code);

                                        if (ii > -1) {
                                            that.data.items[ci].services[ii].isEligible = data[di].isEligible;
                                            that.data.items[ci].services[ii].lastEligibilityCheckedDateUtc = moment().utc();
                                            that.data.items[ci].services[ii].ineligibilityCode = data[di].ineligibilityCode;
                                            that.data.items[ci].services[ii].ineligibilityReason = data[di].ineligibilityReason;
                                            that.data.items[ci].services[ii].earliestEligibleDate = data[di].earliestEligibleDate ? moment(data[di].earliestEligibleDate) : null;
                                        }
                                    }
                                }
                            }
                        } else {
                            if (that.data.items && that.data.items.length > 0) {
                                for (let si = 0; si < that.data.items.length; si++) {

                                    if (that.data.items[si].services && that.data.items[si].services.length > 0) {
                                        for (let sii = 0; sii < that.data.items[si].services.length; sii++) {
                                            that.data.items[si].services[sii].isEligible = null;
                                            that.data.items[si].services[sii].lastEligibilityCheckedDateUtc = null;
                                            that.data.items[si].services[sii].ineligibilityCode = null;
                                            that.data.items[si].services[sii].ineligibilityReason = null;
                                            that.data.items[si].services[sii].earliestEligibleDate = null;
                                        }
                                    }
                                }
                            }
                        }
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            }
            else {
                this.isSaving = false;
                resolve(null);
            }
        })
    }

    addNewItem = () => {
        this.data.items.push(this.getNewItem());
    }

    getNewItem = () => {
        return {
            customerId: null,
            customer: null,
            relationship: null,
            services: [],
        };
    }

    addNewTimeslot = (start, userId) => {
        this.data.timeslots.push(this.getNewTimeslot(start, userId));
    }

    getNewTimeslot = (start, userId) => {
        return {
            start: start,
            userId: userId,
        };
    }

    clear = () => {
        this.id = null;
        this.data.timeslots.clear();
        this.data.items.clear();
        this.data.primaryContactId = null;
        this.data.primaryContact = null;
        this.data.duration = null;
        this.data.question = null;
        this.data.notes.clear();
        this.conflicts.clear();
        this.url = null;
        this.checkUpcomingConflict = true;
        this.hasUnsavedChanges = false;
        this.isReady = false;
        this.isSaving = false;

        if (fn.isFunction(this.cancelAppointmentOfferCreate)) {
            this.cancelAppointmentOfferCreate();
            this.cancelAppointmentOfferCreate = null;
        }
    }

    get recommendedDuration() {
        return this.data.items
            .map(c => {
                var totalDuration = c.services.reduce((acc, service) => {
                    acc += service.duration;
                    const selectedUserId = this.data.userId;
                    // Iterate over userOverrides
                    if (service.userOverrides) {
                        const userOverride = service.userOverrides.find(o => o.userId === selectedUserId);
                        if (userOverride && userOverride.isBookable && userOverride.additionalTimeInMinutes !== 0) {
                            acc += userOverride.additionalTimeInMinutes;
                        }
                    }
                    return acc;
                }, 0);
                return totalDuration;
            })
            .reduce((x, y) => x + y, 0);
    }
}

decorate(AppointmentOfferCreate, {
    id: observable,
    data: observable.deep,
    isInSelectionMode: observable,
    conflicts: observable,
    url: observable,
    checkUpcomingConflict: observable,
    recommendedDuration: computed,
    hasUnsavedChanges: observable,
    isReady: observable,
    isSaving: observable,
    initialize: action,
    checkConflicts: action,
    checkEligibility: action,
    save: action,
    clear: action,
    addNewItem: action,
    addNewTimeslot: action,
})

export default createContext(new AppointmentOfferCreate());