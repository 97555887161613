import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import * as fn from './_functions';
import * as bh from './badgeHelper';

// In this first release of online booking, there'll be only one item in booking; so the isPrimaryContact field is not used
export const getBookingPrimaryContact = (booking) => {
    const item = booking.items.find(i => i.isPrimaryContact)
    if (!!item)
        return item;

    return booking.items[0];
}

export const renderPendingBookingEvent = (event, element, slotDuration, onClick, onDoubleClick) => {
    const eventContainer = document.createElement('div');
    const { extendedProps } = event;
    const booking = extendedProps.booking;
    let size = '';

    eventContainer.classList.add(...Array.from(element.classList));
    eventContainer.classList.add('online-booking-event');

    if (booking.duration < slotDuration) {
        size = 'event-sm';
    } else if (booking.duration >= slotDuration && booking.duration < (slotDuration * 2)) {
        size = 'event-md';
    } else if (booking.duration >= (slotDuration * 2) && booking.duration < (slotDuration * 4)) {
        size = 'event-lg';
    } else {
        size = 'event-xl';
    }

    ReactDOM.render(
        <div className={`event-content ${size} online-booking-content`}
            onClick={fn.isFunction(onClick) ? e => onClick(e, event) : null}
            onDoubleClick={fn.isFunction(onDoubleClick) ? e => onDoubleClick(e, booking) : null}
        >
            <h2 className='title'>
                <span className='name fw-500 d-inline m-0 mr-1 fs-sm'>
                    {booking.title}
                </span>
                {
                    booking.items.map((s, si) => {
                        return <Fragment
                            key={`booking-type-${si}`}
                        >
                            {bh.renderBookingType(s.bookingType)}
                        </Fragment>
                    })
                }
            </h2>
            <div className='p-time fw-500 text-gray-700'>
                <span className='text-gray-700'>
                    {fn.formatTime(booking.start, booking.end)}
                </span>
            </div>
        </div>
        , eventContainer);

    return eventContainer;
}

export const renderOfferTimeslotEvent = (event, element, slotDuration, onClick, onDoubleClick) => {
    const eventContainer = document.createElement('div');
    const { extendedProps } = event;
    const timeslot = extendedProps.appointmentOfferTimeslot;
    const duration = timeslot.duration ? timeslot.duration : moment(event.end).diff(moment(event.start), 'minutes');
    let size = '';

    eventContainer.classList.add(...Array.from(element.classList));
    eventContainer.classList.add('appointment-offer-event');

    if (duration < slotDuration) {
        size = 'event-sm';
    } else if (duration >= slotDuration && duration < (slotDuration * 2)) {
        size = 'event-md';
    } else if (duration >= (slotDuration * 2) && duration < (slotDuration * 4)) {
        size = 'event-lg';
    } else {
        size = 'event-xl';
    }

    ReactDOM.render(
        <div className={`event-content ${size} appointment-offer-content`}
            onClick={fn.isFunction(onClick) ? e => onClick(e, event) : null}
            onDoubleClick={fn.isFunction(onDoubleClick) ? e => onDoubleClick(e, timeslot) : null}
        >
            <h2 className='title'>
                <span className='name fw-500 d-inline m-0 mr-1 fs-sm'>
                    {event.title}
                </span>
            </h2>
            <div className='p-time fw-500 text-gray-700'>
                {'(' + (timeslot.duration ? fn.formatTime(timeslot.start, timeslot.start.clone().add(timeslot.duration, 'minutes')) : (timeslot.start.format('h:mm a'))) + ')'}
            </div>
        </div>
        , eventContainer);

    return eventContainer;
}

export const renderTimeslotScheduleEvent = (event, element, currentTimeslotScheduleId, onClick, onDoubleClick) => {
    const eventContainer = document.createElement('div');
    const { extendedProps } = event;
    const { timeslotSchedule } = extendedProps;

    eventContainer.classList.add(...Array.from(element.classList));
    eventContainer.classList.add('timeslot-schedule-event');

    ReactDOM.render(
        <>
            <div
                className={`event-content` + (currentTimeslotScheduleId && currentTimeslotScheduleId !== event.id && currentTimeslotScheduleId !== timeslotSchedule.id ? ' not-selected' : '')}
                onClick={fn.isFunction(onClick) ? e => onClick(e, event) : null}
                onDoubleClick={fn.isFunction(onDoubleClick) ? e => onDoubleClick(e, event) : null}
            >
                <div className='title'>
                    {
                        timeslotSchedule.bookingTypes.map((a, ai) => {
                            return <Fragment
                                key={`booking-type-${ai}`}
                            >
                                {bh.renderBookingType(a)}
                            </Fragment>
                        })
                    }
                </div>
                <div className='p-time fw-500 text-gray-700'>
                    {fn.formatTimeFromToHtml(moment(timeslotSchedule.startDateTime), moment(timeslotSchedule.endDateTime))}
                </div>
            </div>
        </>,
        eventContainer
    );

    return eventContainer;
}