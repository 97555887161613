import React from 'react';
import { Tooltip } from 'devextreme-react/tooltip';
import uuid from 'react-uuid';
import moment from 'moment';
import Color from 'color';

import * as fn from './_functions';
import * as ah from './appointmentHelper';
import * as uh from './userHelper';
import * as rah from './referralAppointmentHelper';
import * as toh from './todoHelper';
import * as brh from './publicInsuranceBillingReportHelper';
import * as ph from './prescriptionHelper';
import * as sys from './systemHelper';

export const renderAppointmentNew = (appointment, additionalClassNames) => {
    if (!appointment || appointment.isNew) {
        return <span
            className={'badge badge-new text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}
            style={{
                boxSizing: 'border-box'
            }}
        >NEW</span>
    }
}

export const renderAppointmentFirstExam = (appointment, additionalClassNames) => {
    if (!appointment) return;

    if (appointment.isFirstExam && appointment.isExamRequired) {
        return <span
            className={'badge badge-first-exam text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}
            style={{
                boxSizing: 'border-box'
            }}
        >1<sup>st</sup> Exam</span>
    }
}

export const renderAppointmentPrebook = (appointment, additionalClassNames) => {
    if (!appointment || appointment.isPrebook) {
        return <span
            className={'badge badge-prebook text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}
            title={`Pre-booked on ${sys.getFormattedShortDate(moment.utc(appointment.createdDateUtc))}`}
            style={{
                boxSizing: 'border-box'
            }}
        >Prebook</span>
    }
}

export const renderAppointmentStatus = (appointment, additionalClassNames) => {
    if (!appointment) return;

    switch (appointment.status) {
        case 'Canceled':
            return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{ah.getStatusDescription(appointment.status)}</span>

        case 'Rescheduled':
            return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{ah.getStatusDescription(appointment.status)}</span>

        case 'NoShow':
            return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{ah.getStatusDescription(appointment.status)}</span>

        case 'Deleted':
            return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{ah.getStatusDescription(appointment.status)}</span>

        default:
            return;
    }
}

export const renderAppointmentCompleted = (appointment, additionalClassNames) => {
    if (appointment && appointment.status === 'Completed') {
        return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Completed</span>
    }

    return;
}
export const renderAppointmentRescheduledByOffice = (appointment, additionalClassNames) => {
    if (appointment && appointment.rescheduledAppointments && appointment.rescheduledAppointments.filter(a => !a.isRescheduledByCustomer).length > 0) {
        const id = uuid();
        return <>
            <span id={`rescheduled_office_${id}`} className={'badge badge-info text-shadow-0 cursor-pointer' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Rescheduled by Office x{appointment.rescheduledAppointments.filter(a => !a.isRescheduledByCustomer).length}</span>
            <Tooltip
                target={`#rescheduled_office_${id}`}
                position='right'
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
            >
                <table className='fs-xs text-gray-500 text-left'>
                    <tbody>
                        {
                            appointment.rescheduledAppointments.filter(a => !a.isRescheduledByCustomer).map((a, ai) => {
                                return <tr key={`rescheduled_appointment_office_${ai}`}>
                                    <td valign='top' className='pr-1'>
                                        <span className='text-nowrap'>{moment.utc(a.deactivatedDateUtc).local().format('MMM D')}</span>
                                    </td>
                                    <td valign='top' className='pl-1'>
                                        <div className='html'>
                                            <p>Original: {moment(a.start).format('MMM D @ hh:mma')}</p>
                                            <span dangerouslySetInnerHTML={{ __html: (a.deactivatedReason ? fn.plainTextToHtml(a.deactivatedReason) : '<em class="text-gray-600">Unknown</em>') }}></span>
                                        </div>
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </table>
            </Tooltip>
        </>
    }

    return;
}

export const renderAppointmentRescheduledByCustomer = (appointment, additionalClassNames) => {
    if (appointment && appointment.rescheduledAppointments && appointment.rescheduledAppointments.filter(a => a.isRescheduledByCustomer).length > 0) {
        const id = uuid();
        return <>
            <span id={`rescheduled_customer_${id}`} className={'badge badge-warning text-shadow-0 cursor-pointer' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Rescheduled by Patient x{appointment.rescheduledAppointments.filter(a => a.isRescheduledByCustomer).length}</span>
            <Tooltip
                target={`#rescheduled_customer_${id}`}
                position='right'
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
            >
                <table className='fs-xs text-gray-500 text-left'>
                    <tbody>
                        {
                            appointment.rescheduledAppointments.filter(a => a.isRescheduledByCustomer).map((a, ai) => {
                                return <tr key={`rescheduled_appointment_customer_${ai}`}>
                                    <td valign='top' className='pr-1'>
                                        <span className='text-nowrap'>{moment.utc(a.deactivatedDateUtc).local().format('MMM D')}</span>
                                    </td>
                                    <td valign='top' className='pl-1'>
                                        <div className='html'>
                                            <p>Original: {moment(a.start).format('MMM D @ hh:mma')}</p>
                                            <span dangerouslySetInnerHTML={{ __html: (a.deactivatedReason ? fn.plainTextToHtml(a.deactivatedReason) : '<em class="text-gray-600">Unknown</em>') }}></span>
                                        </div>
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </table>
            </Tooltip>
        </>
    }

    return;
}

export const renderAppointmentCanceled = (appointment, additionalClassNames) => {
    if (appointment && appointment.status === 'Canceled') {
        const id = uuid();
        return <>
            <span id={`canceled_${id}`} className={'badge badge-danger text-shadow-0 cursor-pointer' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Canceled</span>
            <Tooltip
                target={`#canceled_${id}`}
                position='right'
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
            >
                <table className='fs-xs text-gray-500 text-left'>
                    <tbody>
                        <tr>
                            <td valign='top' className='pr-1'>
                                <span className='text-nowrap'>{moment.utc(appointment.deactivatedDateUtc).local().format('MMM D')}</span>
                            </td>
                            <td valign='top' className='pl-1'>
                                <div className='html'>
                                    <span dangerouslySetInnerHTML={{ __html: (appointment.deactivatedReason ? fn.plainTextToHtml(appointment.deactivatedReason) : '<em class="text-gray-600">Unknown</em>') }}></span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Tooltip>
        </>
    }

    return;
}

export const renderBookingType = (bookingType, additionalClassNames) => {
    if (!bookingType) return;

    return <span
        className={'badge text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}
        style={{
            backgroundColor: '#ff9999',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#ff1f1f',
            boxSizing: 'border-box',
            color: '#fff',
        }}
    >
        {bookingType.name}
    </span >
}

export const renderServiceCode = (service, additionalClassNames, displayEligibilityStatus = false) => {
    if (!service) return;
    const badgeColor = Color(service.colorHexValue);

    return <span
        className={'badge text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}
        style={{
            backgroundColor: badgeColor.lighten(.8),
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: badgeColor.darken(.3),
            boxSizing: 'border-box',
            color: badgeColor.darken(.3),
        }}
        title={
            displayEligibilityStatus && service.isEligibilityCheckRequired && service.lastEligibilityCheckedDateUtc ?
                (
                    service.isEligible ?
                        `Verified on ${sys.getFormattedLongDate(moment.utc(service.lastEligibilityCheckedDateUtc).local())} @ ${moment.utc(service.lastEligibilityCheckedDateUtc).local().format('h:mm a')} by ${service.lastEligibilityCheckedById ? uh.getDisplayShortNameById(service.lastEligibilityCheckedById) : service.lastEligibilityCheckedBy}` :
                        (
                            service.earliestEligibleDate ?
                                `${service.ineligibilityCode ? `(Error: ${service.ineligibilityCode}) ` : ''}Not eligible until ${sys.getFormattedShortDate(moment(service.earliestEligibleDate))}. - ${sys.getFormattedLongDate(moment.utc(service.lastEligibilityCheckedDateUtc).local())} @ ${moment.utc(service.lastEligibilityCheckedDateUtc).local().format('h:mm a')} by ${service.lastEligibilityCheckedById ? uh.getDisplayShortNameById(service.lastEligibilityCheckedById) : service.lastEligibilityCheckedBy}` :
                                `${(!!service.ineligibilityCode ? `(Error: ${service.ineligibilityCode}) ` : '')}${service.ineligibilityReason} - ${sys.getFormattedLongDate(moment.utc(service.lastEligibilityCheckedDateUtc).local())} @ ${moment.utc(service.lastEligibilityCheckedDateUtc).local().format('h:mm a')} by ${service.lastEligibilityCheckedById ? uh.getDisplayShortNameById(service.lastEligibilityCheckedById) : service.lastEligibilityCheckedBy}`
                        )

                ) :
                null
        }
    >
        {service.code}
        {
            displayEligibilityStatus && service.isEligibilityCheckRequired ?
                <>
                    {
                        service.lastEligibilityCheckedDateUtc ?
                            <>
                                {
                                    service.isEligible ?
                                        <i className='fas fa-check line-height-1 ml-o float-right fs-80'></i> :
                                        <i className='flashing fas fa-exclamation line-height-1 ml-o float-right text-danger fs-80'></i>
                                }
                            </> :
                            <i className='flashing fas fa-question line-height-1 ml-o float-right text-danger-200 fs-80'></i>
                    }
                </> : null
        }
    </span >
}

export const renderServiceSubsidyCode = (service, additionalClassNames, displayEligibilityStatus = false) => {
    if (!service) return;
    const badgeColor = Color(service.colorHexValue);

    return <span
        className={'badge text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}
        style={{
            backgroundColor: badgeColor.lighten(.8),
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: badgeColor.darken(.3),
            boxSizing: 'border-box',
            color: badgeColor.darken(.3),
        }}
        title={
            displayEligibilityStatus && service.isEligibilityCheckRequired && service.lastEligibilityCheckedDateUtc ?
                (
                    service.isEligible ?
                        `Verified on ${sys.getFormattedLongDate(moment.utc(service.lastEligibilityCheckedDateUtc).local())} @ ${moment.utc(service.lastEligibilityCheckedDateUtc).local().format('h:mm a')} by ${service.lastEligibilityCheckedById ? uh.getDisplayShortNameById(service.lastEligibilityCheckedById) : service.lastEligibilityCheckedBy}` :
                        (
                            service.earliestEligibleDate ?
                                `${service.ineligibilityCode ? `(Error: ${service.ineligibilityCode}) ` : ''}Not eligible until ${sys.getFormattedShortDate(moment(service.earliestEligibleDate))}. - ${sys.getFormattedLongDate(moment.utc(service.lastEligibilityCheckedDateUtc).local())} @ ${moment.utc(service.lastEligibilityCheckedDateUtc).local().format('h:mm a')} by ${service.lastEligibilityCheckedById ? uh.getDisplayShortNameById(service.lastEligibilityCheckedById) : service.lastEligibilityCheckedBy}` :
                                `${service.ineligibilityReason} - ${sys.getFormattedLongDate(moment.utc(service.lastEligibilityCheckedDateUtc).local())} @ ${moment.utc(service.lastEligibilityCheckedDateUtc).local().format('h:mm a')} by ${service.lastEligibilityCheckedById ? uh.getDisplayShortNameById(service.lastEligibilityCheckedById) : service.lastEligibilityCheckedBy}`
                        )

                ) :
                null
        }
    >
        {service.subsidyCode}
        {
            displayEligibilityStatus && service.isEligibilityCheckRequired ?
                <>
                    {
                        service.lastEligibilityCheckedDateUtc ?
                            <>
                                {
                                    service.isEligible ?
                                        <i className='fas fa-check line-height-1 ml-o float-right fs-80'></i> :
                                        <i className='flashing fas fa-exclamation line-height-1 ml-o float-right text-danger fs-80'></i>
                                }
                            </> :
                            <i className='flashing fas fa-question line-height-1 ml-o float-right text-danger-200 fs-80'></i>
                    }
                </> : null
        }
    </span>
}

export const renderServiceDiagnosticCode = (code, description, additionalClassNames, includeDescription = true) => {
    if (!code) return;

    return <span
        className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}
        title={description}
    >
        <span className='d-inline-block text-truncate text-truncate-xs' style={{ marginBottom: '-2px' }}>{code}{!!description && !!includeDescription ? `- ${description}` : ''}</span>
    </span>
}

export const renderExamAppointmentNew = (exam, additionalClassNames) => {
    if (!exam || !exam.previousExam) {
        return <span
            className={'badge badge-first text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}
            style={{
                boxSizing: 'border-box'
            }}
        >NEW</span>
    }
}

export const renderWorkOrderRedo = (order, additionalClassNames) => {
    if (!order) return;

    if (order.isRedo) {
        return <span className={'badge badge-warning-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Redo</span></span>;
    }
}

export const renderWorkOrderStatus = (order, additionalClassNames) => {
    if (!order) return;

    if (order.isDeactivated) {
        return <span className={'badge badge-darker text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Deleted</span></span>;
    }
    else if (order.isExpired) {
        return <span className={'badge badge-darker text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Expired</span></span>;
    }
    else {
        switch (order.status) {
            case 'Incomplete':
                return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Incomplete</span>

            case 'Submitted':
                return <span className={'badge badge-secondary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Submitted</span>

            case 'PartiallyReceived':
                return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Partially Received</span>

            case 'Ready':
                return <span className={'badge badge-success text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Ready</span>

            case 'CustomerNotified':
                return <span className={'badge badge-success text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Customer Notified</span>

            case 'Completed':
                return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Completed</span>

            case 'OnHold':
                return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>On Hold</span>

            case 'Abandoned':
                return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Abandoned</span>

            default:
                return <></>
        }
    }
}

export const renderPurchaseStatus = (purchase, additionalClassNames) => {
    if (!purchase) return <></>;

    const { status } = purchase;

    switch (status) {
        case 'Incomplete':
            return <span className={'badge badge-danger-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Incomplete</span></span>;

        case 'Completed':
            return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Completed</span>

        case 'Deleted':
            return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Deleted</span>

        case 'Refunded':
            return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Refunded</span></span>;

        case 'Voided':
            return <span className={'badge badge-darker text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Voided</span></span>;

        case 'Uncollectible':
            return <span className={'badge badge-danger-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Uncollectible</span>

        default:
            return <></>;
    }
}

export const renderPurchaseBalanceType = (purchase, additionalClassNames) => {
    if (!purchase) return <></>;

    let result;
    const { balanceType } = purchase;

    switch (balanceType) {
        case 'NoCharge':
            result = <span className={'badge badge-darker text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={fn.stripHtml(purchase.noChargeReason)}><span>No charge</span></span>;
            break;

        case 'Uncollectible':
            result = <span className={'badge badge-danger-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={fn.stripHtml(purchase.uncollectibleReason)}><span>Uncollectible</span></span>;
            break;

        case 'Voided':
            result = <span className={'badge badge-darker text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Voided</span></span>;
            break;

        case 'InsuranceGovernmentPending':
            result = <span className={'badge badge-primary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Insurance / Government pending</span></span>;
            break;

        case 'InsurancePending':
            result = <span className={'badge badge-primary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Insurance pending</span></span>;
            break;

        case 'GovernmentPending':
            result = <span className={'badge badge-primary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Government program pending</span></span>;
            break;

        case 'OverPaid':
            result = <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Overpaid</span></span>;
            break;

        case 'Paid':
            result = <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Paid in full</span></span>;
            break;

        case 'Outstanding':
            const days = purchase.remainingBalance > 0 ? moment().startOf('day').diff(moment(purchase.dueDate).startOf('day'), 'days') : 0;
            const isOutstanding = days >= 0;
            const outstandingDays = Math.abs(days);
            let outstandingTerm;

            if (isNaN(outstandingDays)) {
                outstandingTerm = null;
            } else if (outstandingDays >= 120) {
                outstandingTerm = '120+ days';
            } else if (outstandingDays >= 30) {
                outstandingTerm = `${Math.trunc(outstandingDays / 30) * 30}+ days`;
            } else if (outstandingDays >= 14 || !isOutstanding) {
                outstandingTerm = `${outstandingDays} days`;
            }

            if (isOutstanding) {
                result = <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Outstanding{outstandingTerm ? ` (${outstandingTerm})` : ''}</span></span>;
            }
            else {
                result = <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{`Due${outstandingTerm ? ` in ${outstandingTerm}` : ' soon'}`.replace('in 1 days', 'tomorrow')}</span></span>;
            }
            break;

        default:
            result = <></>;
            break;
    }

    return result;
}

export const renderPurchaseBalanceStatus = (purchase, additionalClassNames) => {
    if (!purchase) return <></>;

    const { status } = purchase;

    switch (status) {
        case 'Completed':
            return renderPurchaseBalanceType(purchase, additionalClassNames);

        default:
            return renderPurchaseStatus(purchase, additionalClassNames);
    }
}

export const renderPurchaseReferenceType = (referenceType, additionalClassNames) => {
    if (!referenceType) return <></>;

    return <span className={'badge badge-secondary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{referenceType}</span>;
}

export const renderPurchaseReferenceTypes = (purchase, additionalClassNames) => {
    if (!purchase || !purchase.referenceTypes || purchase.referenceTypes.length === 0) return <></>;

    return purchase.referenceTypes.map((r, ri) => {
        return <span key={`purchase_order_reference_type_${ri}`} className={'badge badge-secondary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{r}</span>
    });
}

export const renderPurchaseOutstanding = (purchase, additionalClassNames) => {
    if (!purchase) return <></>;

    if (purchase.remainingBalance > 0) {


        const outstandingDays = purchase.remainingBalance > 0 ? moment().startOf('day').diff(moment.utc(purchase.createdDateUtc).startOf('day'), 'days') : 0;

        if (outstandingDays >= 120) {
            return <span className={'badge badge-danger text-white text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>120+ days</span>;
        } else if (outstandingDays >= 30) {
            return <span className={'badge badge-danger text-white text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{Math.trunc(outstandingDays / 30) * 30}+ days</span>;
        } else if (outstandingDays >= 14) {
            return <span className={'badge badge-danger text-white text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{outstandingDays} days</span>;
        }
    }

    return <></>
}

export const renderPurchaseAmount = (purchase, additionalClassNames) => {
    if (!purchase) return <></>;

    const amount = fn.formatCurrency(purchase.total);

    if (purchase.isNoCharge) {
        return <span className={'text-gray-700' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>-- --</span>;
    } else if (purchase.isUncollectible) {
        return <span className={'text-gray-700' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><small className='fw-500 text-danger text-strike'>({fn.formatCurrency(purchase.remainingBalance)}*)</small> {amount}</span>;
    } else if (purchase.status === 'Refunded' || !!purchase.refundPurchaseId) {
        return <span className={'text-gray-600 text-strike' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{amount}</span>;
    } else if (purchase.status === 'Voided') {
        return <span className={'text-gray-600 text-strike' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{amount}</span>;
    } else if (purchase.remainingBalance > 0.0) {
        return <span className={'text-gray-700' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><small className='fw-500 text-danger'>({fn.formatCurrency(purchase.remainingBalance)}*)</small> {amount}</span>;
    } else if (purchase.remainingBalance < 0.0) {
        return <span className={'text-danger' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{amount}</span>;
    } else {
        return <span className={'text-gray-700' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{amount}</span>;
    }
}

export const renderTransactionAmount = (transaction, additionalClassNames) => {
    if (!transaction) return <></>;

    const amount = fn.formatCurrency(transaction.total);
    return <span className={'text-gray-700' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{amount}</span>;
}

export const renderInventoryHistoryType = (inventoryHistory, additionalClassNames) => {
    if (!inventoryHistory) return <></>;

    const { type } = inventoryHistory;

    switch (type) {
        case 'Adjustment':
            return <span className={'badge bg-warning-600 text-white text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Adjustment</span>;

        case 'Sale':
            return <span className={'badge bg-success-600 text-white text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Sale</span>;

        case 'Return':
            return <span className={'badge bg-info-600 text-white text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Return</span>;

        case 'System':
            return <span className={'badge bg-gray-600 text-white text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>System</span>;

        default:
            return;
    }
}

export const renderTransactionType = (transaction, additionalClassNames) => {
    if (!transaction) return <></>;

    if (transaction.transactionType === 'Sales') {
        return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Sales</span></span>;
    } else if (transaction.transactionType === 'Return') {
        return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Return</span></span>;
    } else if (transaction.transactionType === 'Void') {
        return <span className={'badge badge-darker text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Void</span></span>;
    }

    return <></>
}

export const renderServiceFutureChangeIndicator = (service, attributes, additionalClassNames) => {
    if (attributes.some(a => a.serviceId === service.id)) {
        return <span className={'badge badge-solid-success text-shadow-0 badge-dot float-left flashing mr-1 ml-n2' + (additionalClassNames ? ` ${additionalClassNames}` : '')}></span>
    }
}

export const renderTodoStatus = (todo, includeWorkingOnName, additionalClassNames) => {
    if (!todo) return;

    if (todo.isDeactivated) {
        return <span className={'badge bg-gray-50 text-gray-700 border border-1 border-gray text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Deleted</span></span>;
    }
    else if (todo.completedDateUtc || todo.status === 'Completed') {
        return <span className={'badge bg-info-700 border border-1 border-info-700 text-white text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Completed</span></span>;
    }
    else if (todo.status === 'Canceled') {
        return <span className={'badge bg-gray-50 text-gray-700 border border-1 border-gray text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Canceled</span></span>;
    }
    else if (todo.status === 'InProgress') {
        return <span className={'badge bg-info-50 text-info-700 border border-1 border-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>In Progress{includeWorkingOnName === true && todo.workingOnById ? ` - ${uh.getDisplayShortNameById(todo.workingOnById)}` : ''}</span></span>;
    }
    else if (todo.status === 'Reopened') {
        return <span className={'badge bg-warning-50 border border-1 border-warning text-warning-800 text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Reopened</span></span>;
    }
    else {
        return <span className={'badge bg-warning-50 border border-1 border-warning text-warning-800 text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Pending</span></span>;
    }
}

export const renderTodoDueStatus = (todo, additionalClassNames, isShortFormat = false) => {
    if (!todo) return;

    if (todo.isDeactivated || todo.completedDateUtc || todo.status === 'Completed' || todo.status === 'Canceled') {
        return <></>;
    }
    else if (todo.dueDateTimeUtc) {
        const now = moment();
        const dueDate = moment.utc(todo.dueDateTimeUtc).local();
        const isOverdue = now.isSameOrAfter(dueDate);

        if (isOverdue) {
            return <span className={'flashing slow badge badge-danger-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={`Due on ${sys.getFormattedLongDate(dueDate)}`}><span>Overdue</span></span>;
        } else {
            const dueInDays = dueDate.clone().startOf('day').diff(now.clone().startOf('day'), 'days');

            if (dueInDays > 7) {
                return isShortFormat ?
                    <></> :
                    <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={`Due on ${sys.getFormattedLongDate(dueDate)}`}><span>Due on {sys.getFormattedLongDate(dueDate.local())}</span></span>;
            }
            else if (dueInDays > 0) {
                return isShortFormat ?
                    <></> :
                    <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={`Due on ${sys.getFormattedLongDate(dueDate)}`}><span>Due in {dueInDays} day(s)</span></span>;
            }

            const dueInHours = dueDate.clone().startOf('hour').diff(now.clone().startOf('hour'), 'hours');

            if (dueInHours > 0) {
                return isShortFormat ?
                    <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={`Due today at ${fn.formatTime(dueDate)}`}><span>Due soon</span></span> :
                    <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={`Due today at ${fn.formatTime(dueDate)}`}><span>Due in {dueInHours} hour(s)</span></span>;
            }
            else {
                const dueInMinutes = dueDate.clone().diff(now.clone(), 'minutes');

                if (dueInMinutes === 0) {
                    return <span className={'badge badge-danger-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={`Due today at ${fn.formatTime(dueDate)}`}><span>Due now</span></span>;
                }
                else {
                    return isShortFormat ?
                        <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={`Due today at ${fn.formatTime(dueDate)}`}><span>Due soon</span></span> :
                        <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={`Due today at ${fn.formatTime(dueDate)}`}><span>Due in {dueInMinutes} minute(s)</span></span>;
                }
            }
        }
    }
    else {
        return isShortFormat ?
            <></> :
            <span className={'badge badge-success-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title='As soon as possible'><span>As soon as possible</span></span>;
    }
}

export const renderReferralStatus = (referral, additionalClassNames) => {
    if (!referral) return;

    if (!referral.isCompleted) {
        return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Draft - Not sent</span></span>;
    }

    return;
}

export const renderTodoInstanceStatus = (todoInstance, additionalClassNames) => {
    if (!todoInstance) return;

    const description = toh.getTodoInstanceStatusDescription(todoInstance.status);

    if (todoInstance.status === 'Completed') {
        return <span className={'badge badge-success text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (todoInstance.status === 'InProgress') {
        return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (todoInstance.status === 'Pending') {
        return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else {
        return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
}

export const renderReferralAppointmentStatus = (referralAppointment, additionalClassNames) => {
    if (!referralAppointment) return;

    const description = rah.getReferralAppointmentStatusDescription(referralAppointment.status);

    if (referralAppointment.status === 'Ready') {
        return <span className={'badge badge-success-light text-success-500 text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (referralAppointment.status === 'Requested') {
        return <span className={'badge badge-success text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (referralAppointment.status === 'Booked') {
        return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (referralAppointment.status === 'Notified') {
        return <span className={'badge badge-info-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (referralAppointment.status === 'Confirmed') {
        return <span className={'badge badge-info-darker text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (referralAppointment.status === 'Seen') {
        return <span className={'badge badge-success-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (referralAppointment.status === 'Completed') {
        return <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (referralAppointment.status === 'NoResponse') {
        return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    else if (referralAppointment.status === 'Canceled') {
        return <span className={'badge badge-warning-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }

    return;
}

export const renderMedicalReportStatus = (medicalReport, additionalClassNames) => {
    if (!medicalReport) return;

    if (medicalReport.isCompleted) {
        return <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Completed</span></span>;
    }
    if (!medicalReport.isCompleted) {
        return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>Draft - Not sent</span></span>;
    }

    return;
}

export const renderPublicInsuranceBillingReportStatus = (publicInsuranceBillingReport, additionalClassNames) => {
    if (!publicInsuranceBillingReport) return;

    const description = brh.getPublicInsuranceBillingReportStatusDescription(publicInsuranceBillingReport.status);

    if (publicInsuranceBillingReport.status === 'Ready') {
        return <span className={'badge badge-success text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    if (publicInsuranceBillingReport.status === 'NotReady') {
        return <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    if (publicInsuranceBillingReport.status === 'Unknown') {
        return <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    if (publicInsuranceBillingReport.status === 'Submitted') {
        return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    if (publicInsuranceBillingReport.status === 'Failed') {
        return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    if (publicInsuranceBillingReport.status === 'Paid') {
        return <span className={'badge badge-info-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }
    if (publicInsuranceBillingReport.status === 'Scheduled') {
        return <span className={'badge badge-success-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{description}</span></span>;
    }

    return;
}

export const renderPrescriptionStatus = (prescription, additionalClassNames) => {
    const status = ph.getStatus(prescription);
    let css = 'badge-primary-light';

    switch (status) {
        case 'Outside':
            css = 'badge-warning-dark';
            break;

        case 'Draft':
            css = 'badge-danger-light';
            break;

        case 'Signed':
            css = 'badge-info-dark';
            break;

        case 'Finalized':
            css = 'badge-alt';
            break;

        case 'Pending':
            css = 'badge-primary';
            break;

        default:
            break;
    }

    return <span className={`badge ${css} text-shadow-0` + (additionalClassNames ? ` ${additionalClassNames}` : '')}><span>{status}</span></span>;
}

export const renderLastExamSubsidized = (customer, additionalClassNames) => {
    if (!customer) return;

    if (customer.isLastFullExamSubsidized) {
        return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Yes</span>
    }
    else {
        return <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>No</span>
    }
}

export const renderCustomerStatus = (customer, additionalClassNames) => {
    if (!customer) return;

    switch (customer.status) {
        case 'Active':
            return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Active</span>

        case 'Inactive':
            return <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Inactive</span>

        case 'Moved':
            return <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Moved</span>

        case 'Transferred':
            return <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Transferred</span>

        case 'Deceased':
            return <span className={'badge badge-dark text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Deceased</span>

        case 'Duplicate':
            return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Duplicate</span>

        default:
            return;
    }
}

export const renderHealthCardStatus = (customer, additionalClassNames) => {
    if (!customer || !customer.patientProfile || !customer.patientProfile.healthCardNumber) return <></>;

    const { isHealthCardVerified, isHealthCardValid, isHealthCardExpired, healthCardLastVerificationResponse } = customer.patientProfile;

    if (isHealthCardExpired) {
        return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title='Expired'>Expired</span>;
    }
    else if (!isHealthCardVerified) {
        return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title='Not verified'>Not verified</span>
    }
    else if (!isHealthCardValid) {
        return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')} title={healthCardLastVerificationResponse}>Invalid</span>;
    }

    return <></>;
}

export const renderCommunicationStatus = (communication, additionalClassNames) => {
    if (!communication) return;

    switch (communication.status) {
        case 'Queued':
            return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Queued</span>

        case 'Sent':
            return <span className={'badge badge-success text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Sent</span>

        case 'Delivered':
            return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Delivered</span>

        case 'Failed':
            return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Failed</span>

        case 'Hidden':
            return <span className={'badge badge-secondary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Hidden</span>

        case 'Deleted':
            return <span className={'badge badge-secondary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Deleted</span>

        case 'Duplicate':
            return <span className={'badge badge-secondary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Duplicate</span>

        default:
            return;
    }
}

export const renderCommunicationExternalStatus = (communication, additionalClassNames) => {
    if (!communication) return;

    switch (communication.externalStatus) {
        case 'Queued':
            return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Queued</span>

        case 'Sent':
            return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Sent</span>

        case 'Failed':
            return <span className={'badge badge-danger text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Failed</span>

        case 'Hidden':
            return <span className={'badge badge-secondary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Hidden</span>

        case 'Deleted':
            return <span className={'badge badge-secondary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Deleted</span>

        default:
            return;
    }
}

export const renderSpokenLanguage = (customer, additionalClassNames) => {
    const spokenLanguage = customer && customer.spokenLanguage ? customer.spokenLanguage : null;
    return spokenLanguage ? <span><span className={'badge badge-secondary text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>{spokenLanguage}</span></span> : null;
}

export const renderEndOfDayStatus = (endOfDay, additionalClassNames) => {
    if (!endOfDay) return;

    if (endOfDay.isCompleted) {
        if (endOfDay.isVerified) {
            return <span className={'badge badge-info text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Signed off</span>
        }

        return <span className={'badge badge-warning text-shadow-0' + (additionalClassNames ? ` ${additionalClassNames}` : '')}>Auto</span>
    }

    return;
}