import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import { toJS } from 'mobx';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import { DropdownList, Multiselect } from 'react-widgets'
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import BodyEnd from '../../_shared/BodyEnd';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import CustomerFilter from '../../_shared/CustomerFilter';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import ConfirmModal from '../../_shared/ConfirmModalComponent';

import AppointmentOfferCreateStore from '../../../../stores/AppointmentOfferCreateStore';
import CustomerCreateStore from '../../../../stores/CustomerCreateStore';
import NoteCreateStore from '../../../../stores/NoteCreateStore';
import AuthStore from '../../../../stores/AuthStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as ph from '../../../../utilities/personHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';
import * as uh from '../../../../utilities/userHelper';

import api from '../../../../api';

import './NewAppointmentOffer.scss';

moment.locale('en');
momentLocalizer();

const APPOINTMENT_OFFER_CUSTOMER_INPUT_ID = '_appointment_offer_customer_input_';

function NewAppointmentOffer(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const searchTimer = useRef(null);
    const startTimePickerTimer = useRef(null);
    const endTimePickerTimer = useRef(null);
    const confirmModalRef = useRef(null);
    const newTimeslotCustomerFilterRef = useRef([]);
    const cache = useContext(CacheStore);
    const appointmentOffer = useContext(AppointmentOfferCreateStore);
    const newCustomer = useContext(CustomerCreateStore);
    const newNote = useContext(NoteCreateStore);
    const auth = useContext(AuthStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [isReady, setIsReady] = useState(false);
    const [services, setServices] = useState([]);
    const [customerSearchResult, setCustomerSearchResult] = useState('');
    const [eligibles, setEligibles] = useState(null);
    const [ineligibles, setIneligibles] = useState(null);
    const [mohReady, setMohReady] = useState(null);
    const [relationships, setRelationships] = useState([]);
    const [relationshipUpdates, setRelationshipUpdates] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        api.Services.all()
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data) {
                        const bookableServices = data.filter(d => !!d.isBookable);
                        if (bookableServices && bookableServices.length > 0) {
                            setServices(bookableServices);
                        }
                    }
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsReady(true);
                    focusTimer.current = setTimeout(() => {
                        if (isMounted.current && newTimeslotCustomerFilterRef.current && newTimeslotCustomerFilterRef.current[0] && newTimeslotCustomerFilterRef.current[0].element) {
                            if (props.extraProps) {
                                if (props.extraProps.existingCustomers && props.extraProps.existingCustomers.length > 0) {
                                    handleCustomerSearchClick(0, props.extraProps.existingCustomer);
                                }

                                if (props.extraProps.services) {
                                    appointmentOffer.data.items[0].services = props.extraProps.services;
                                    appointmentOffer.data.duration = appointmentOffer.recommendedDuration;
                                }
                            }
                            else {
                                newTimeslotCustomerFilterRef.current[0].element.focus();
                            }
                        }

                        setTimeout(() => {
                            checkConflicts();
                            refreshPrimaryContacts();
                            checkEligibility();
                        }, 300);
                    }, 500);
                }
            })

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            if (searchTimer.current) { clearTimeout(searchTimer.current); searchTimer.current = null; }
            if (startTimePickerTimer.current) { clearTimeout(startTimePickerTimer.current); startTimePickerTimer.current = null; }
            if (endTimePickerTimer.current) { clearTimeout(endTimePickerTimer.current); endTimePickerTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (appointmentOffer.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    const newCustomers = appointmentOffer.data.items.filter(s => !s.customerId && s.customer).map(s => { return s.customer });
                    if (newCustomers && newCustomers.length > 0) {
                        confirmModalRef.current.show({
                            icon: <i className={`${oh.getIcon('customer', 'new')} text-success mr-2`}></i>,
                            message: <><span><strong>Save new customer(s)</strong> before exiting?</span></>,
                            option1ClassName: 'btn btn-success shadow-0 bootbox-accept',
                            option1Text: 'Yes',
                            cancelText: 'No',
                            onOption1Click: handleSaveNewCustomersBeforeExit,
                            onCancel: () => { props.onCancel(); }
                        })
                    }
                    else {
                        props.onCancel();
                    }
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSaveNewCustomersBeforeExit = (event) => {
        handleCommitUpdate(event, false)
            .then((response) => {
                if (isMounted.current && response && response.length > 0) {
                    toast.dark(() => <p data-cust-ctd>Customer(s) created.</p>);
                }

                if (props.onCancel && fn.isFunction(props.onCancel)) {
                    props.onCancel();
                }
            });
    }

    const checkConflicts = () => {
        appointmentOffer.checkConflicts();
    }

    const checkEligibility = () => {
        if (appointmentOffer.data && appointmentOffer.data.items && appointmentOffer.data.items.length > 0) {
            appointmentOffer.checkEligibility()
                .then(data => {
                    if (isMounted.current) {
                        const eligibleData = data && data.some(d => d.isPublicHealthChecked && d.isEligible) ? data.filter(d => d.isPublicHealthChecked && d.isEligible) : null;
                        const ineligibleData = data && data.some(d => !d.isPublicHealthChecked || !d.isEligible) ? data.filter(d => !d.isPublicHealthChecked || !d.isEligible) : null;
                        const isMohReady = data && !data.some(e => !e.isMohReady);

                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);
                        setMohReady(isMohReady);
                    }
                })
        } else {
            setEligibles(null);
            setIneligibles(null);
            setMohReady(null);
        }
    }

    const handlePrimaryContactChange = (primaryContact) => {
        appointmentOffer.data.primaryContactId = primaryContact ? primaryContact.id : null;
        appointmentOffer.data.primaryContact = primaryContact;

        if (primaryContact) {
            api.CustomerRelationships.get(primaryContact.id)
                .then(({ data }) => {
                    const filteredRelationships = data && data.length > 0 && data.some(r => r.customerId === primaryContact.id) ? data.filter(r => r.customerId === primaryContact.id) : [];

                    for (let i = 0; i < appointmentOffer.data.items.length; i++) {
                        if (appointmentOffer.data.items[i].customer) {
                            if (appointmentOffer.data.items[i].customer.id === primaryContact.id) {
                                appointmentOffer.data.items[i].relationship = null;
                            }
                            else {
                                const filteredRelationship = filteredRelationships.filter(r => r.relatedTo.id === appointmentOffer.data.items[i].customer.id)[0];

                                if (filteredRelationship) {
                                    appointmentOffer.data.items[i].relationship = filteredRelationship.relationship;
                                }
                                else {
                                    appointmentOffer.data.items[i].relationship = null;
                                }
                            }
                        }
                    }
                })
        }
        else {
            for (let i = 0; i < appointmentOffer.data.items.length; i++) {
                appointmentOffer.data.items[i].relationship = null;
            }
        }

        setRelationshipUpdates([]);
        appointmentOffer.hasUnsavedChanges = true;
    }

    const handleNewCustomer = () => {
        let extraProps;

        if (!newCustomer.isReady) {
            newCustomer.initialize(false);
        }
        if (!!appointmentOffer.data.primaryContact) {
            extraProps = { primaryContact: toJS(appointmentOffer.data.primaryContact) };
        }
        // setExistingCustomerData(null);
        setCustomerSearchResult(null);
        quickDrawer.activateQuickDrawer('customer', 'create', extraProps, handleNewCustomerSuccess, handleNewCustomerCancel);
    }

    const handleNewCustomerSuccess = customer => {
        if (customer && customer.data) {
            const index = appointmentOffer.data.items.length - 1;
            const { data } = customer;

            data.id = uuid();
            data.referenceId = data.id;
            appointmentOffer.data.items[index].customer = data;

            if (!appointmentOffer.data.primaryContact) {
                appointmentOffer.data.primaryContact = data;
                appointmentOffer.data.primaryContactId = data.id;
            }

            newCustomer.clear();
            appointmentOffer.hasUnsavedChanges = true;
        }
    }

    const handleNewCustomerCancel = () => {
        newCustomer.clear();
    }

    const handleCustomerSearchChange = (event, index, term) => {
        if (appointmentOffer.data.items[index].customerId || appointmentOffer.data.items[index].customer) {
            appointmentOffer.data.items[index].customerId = null;
            appointmentOffer.data.items[index].customer = null;
            appointmentOffer.data.items[index].customer.services.clear();
        }

        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }

        if (term && term.length >= 2) {
            const exclude = appointmentOffer.data.items.some(c => !!c.customer) ? appointmentOffer.data.items.filter(c => !!c.customer).map(c => { return c.customer.id }) : null;
            api.Customers.fullSearch(term, false, false, 5, null, null, null, null, null, exclude)
                .then(({ data }) => {
                    if (isMounted.current) {
                        setCustomerSearchResult(data.result);
                    }
                })
        } else {
            setCustomerSearchResult(null);
        }
        setEligibles(null);
        setIneligibles(null);
    }

    const handleCustomerSearchClick = (index, customer) => {
        newTimeslotCustomerFilterRef.current[index].isLoading(true);
        setCustomerSearchResult(null);

        api.Customers.get(customer.id)
            .then(({ data: customerData }) => {
                if (isMounted.current) {
                    if (newTimeslotCustomerFilterRef.current && newTimeslotCustomerFilterRef.current[index]) {
                        newTimeslotCustomerFilterRef.current[index].isLoading(false);
                    }

                    if (appointmentOffer.data.items && appointmentOffer.data.items[index]) {
                        appointmentOffer.data.items[index].customerId = customerData.id;
                        appointmentOffer.data.items[index].customer = customerData;
                    }

                    checkConflicts();
                    refreshPrimaryContacts()
                        .then((relationshipData) => {
                            if (isMounted.current) {
                                if (!appointmentOffer.data.primaryContact) {
                                    if (customerData.primaryContactPerson) {
                                        appointmentOffer.data.primaryContactId = customerData.primaryContactPerson.id;
                                        appointmentOffer.data.primaryContact = customerData.primaryContactPerson;
                                    }
                                    else {
                                        appointmentOffer.data.primaryContactId = customerData.id;
                                        appointmentOffer.data.primaryContact = customerData;
                                    }
                                }

                                if (relationshipData && relationshipData.length > 0) {
                                    const filteredRelationships = relationshipData.filter(r => r.customerId === appointmentOffer.data.primaryContact.id);

                                    if (filteredRelationships && filteredRelationships.length > 0) {
                                        const filteredRelationship = filteredRelationships.filter(r => r.relatedTo.id === customerData.id)[0];

                                        if (filteredRelationship) {
                                            appointmentOffer.data.items[index].relationship = filteredRelationship.relationship;
                                        }
                                    }
                                }
                            }
                        })

                    setTimeout(() => {
                        if (isMounted.current) {
                            const input = document.querySelector(`#${APPOINTMENT_OFFER_CUSTOMER_INPUT_ID}${(index + 1)}`);

                            if (input) {
                                input.focus();
                            }
                        }
                    }, 1000);
                    appointmentOffer.hasUnsavedChanges = true;
                }
            })
    }

    const handleCustomerAdd = () => {
        appointmentOffer.addNewItem();
        appointmentOffer.hasUnsavedChanges = true;
    }

    const handleCustomerRemove = (event, index) => {
        const primaryContacts = getPrimaryContactOptions();

        appointmentOffer.data.items.splice(index, 1);

        if (!appointmentOffer.data.items) {
            appointmentOffer.data.items = [];
        }

        if (appointmentOffer.data.items.length === 0) {
            appointmentOffer.addNewItem();
        }

        if (appointmentOffer.data.items.some(c => c.customer) && !primaryContacts.some(p => p.id === appointmentOffer.data.primaryContactId)) {
            appointmentOffer.data.primaryContact = appointmentOffer.data.items.filter(c => c.customer)[0].customer;
            appointmentOffer.data.primaryContactId = appointmentOffer.data.items.filter(c => c.customer)[0].customer.id;
        }
        else if (!appointmentOffer.data.items.some(c => c.customer)) {
            appointmentOffer.data.primaryContact = null;
            appointmentOffer.data.primaryContactId = null;
            setRelationships([]);
        }

        checkEligibility();
        checkConflicts();
        appointmentOffer.hasUnsavedChanges = true;
    }

    const handleRelationshipChange = (event, customer, index) => {
        if (appointmentOffer.data.primaryContact && customer && customer.id) {
            const relationship = event.target.value;
            const tempRelationshipUpdates = Array.from(new Set([...relationshipUpdates]));
            const ri = relationshipUpdates.findIndex(r => r.party1Id === appointmentOffer.data.primaryContact.id && r.party2Id === customer.id);
            const relationshipUpdate = {
                party1Id: appointmentOffer.data.primaryContact.id,
                party2Id: customer.id,
                relationshipType: relationship
            }

            if (ri > -1) {
                tempRelationshipUpdates[ri] = relationshipUpdate;
            }
            else {
                tempRelationshipUpdates.push(relationshipUpdate);
            }

            setRelationshipUpdates(tempRelationshipUpdates);
            appointmentOffer.data.items[index].relationship = relationship;
            appointmentOffer.hasUnsavedChanges = true;
        }
    }

    const handleServiceSelection = (index, tag, metadata) => {
        switch (metadata.action) {
            case 'insert':
                if (!appointmentOffer.data.items[index].services.some(s => s.id === metadata.dataItem.id)) {
                    appointmentOffer.data.items[index].services.push({
                        id: metadata.dataItem.id,
                        code: metadata.dataItem.code,
                        name: metadata.dataItem.code,
                        duration: metadata.dataItem.duration,
                        isRepeatable: metadata.dataItem.isRepeatable,
                        userOverrides: metadata.dataItem.userOverrides
                    });
                }

                if (!!appointmentOffer.data.existingAppointmentId) {  // This means it's converting from an individual appointment to a group appointment.
                    appointmentOffer.data.duration = appointmentOffer.data.duration + metadata.dataItem.duration;
                }
                break;

            case 'remove':
                const serviceIndex = appointmentOffer.data.items[index].services.findIndex(s => s.id === metadata.dataItem.id);
                if (serviceIndex !== -1) {
                    appointmentOffer.data.items[index].services.splice(serviceIndex, 1);

                    if (!!appointmentOffer.data.existingAppointmentId) {  // This means it's converting from an individual appointment to a group appointment.
                        appointmentOffer.data.duration = appointmentOffer.data.duration - metadata.dataItem.duration;
                    }
                }
                break;

            default:
                break;
        }

        if (!appointmentOffer.data.existingAppointmentId) {
            appointmentOffer.data.duration = appointmentOffer.recommendedDuration;
        }

        appointmentOffer.hasUnsavedChanges = true;
        checkConflicts();
        checkEligibility();
    }

    const handleDurationChange = event => {
        appointmentOffer.data.duration = parseInt(event.target.value, 10);
        appointmentOffer.hasUnsavedChanges = true;
    }

    const handleDurationBlur = () => {
        if (appointmentOffer.hasUnsavedChanges) {
            checkConflicts();
        }
    }

    const handleEnterSelectionMode = () => {
        appointmentOffer.isInSelectionMode = true;
        quickDrawer.deactivateAll();
    }

    const handleQuestionChange = event => {
        const { value } = event.target;
        appointmentOffer.data.question = value;
        appointmentOffer.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            appointmentOffer.checkEligibility(true)
                .then(eligibilityData => {
                    if (isMounted.current) {
                        const eligibleData = eligibilityData && eligibilityData.some(d => d.isEligible) ? eligibilityData.filter(d => d.isEligible) : null;
                        const ineligibleData = eligibilityData && eligibilityData.some(d => !d.isEligible) ? eligibilityData.filter(d => !d.isEligible) : null;

                        setEligibles(eligibleData);
                        setIneligibles(ineligibleData);

                        if (!ineligibleData) {
                            appointmentOffer.checkConflicts(true)
                                .then(conflictData => {
                                    if (isMounted.current) {
                                        if (conflictData && conflictData.some(c => c.hasConflict)) {
                                            showConfirmConflictsModal();
                                        } else {
                                            handleCommitUpdate(event, true);
                                        }
                                    }
                                })
                        } else {
                            confirmModalRef.current.show({
                                icon: <i className={`${oh.getIcon('appointment', 'new')} text-warning mr-2`}></i>,
                                message: <>Some patients&nbsp;<strong>might not be eligible</strong>&nbsp;for their service(s).  Continue?</>,
                                option1ClassName: 'btn btn-warning shadow-0 bootbox-accept',
                                onOption1Click: handleIneligibleConfirm,
                                onCancel: handleIneligibleCancel
                            });
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }

    const showConfirmConflictsModal = () => {
        confirmModalRef.current.show({
            icon: <i className={`${oh.getIcon('appointment', 'new')} text-warning mr-2`}></i>,
            message: <><span>Appointment&nbsp;<strong>conflict(s) detected</strong>.  Continue?</span></>,
            option1ClassName: 'btn btn-warning shadow-0 bootbox-accept',
            onOption1Click: handleConflictsConfirm,
            onCancel: handleConflictsCancel
        });
    }

    const handleSaveNewCustomers = () => {
        return new Promise((resolve) => {
            const newCustomers = appointmentOffer.data.items.filter(s => !s.customerId && s.customer).map(s => { return s.customer });

            if (!!newCustomers && newCustomers.length > 0) {
                const newCustomerRequests = newCustomers.map(n => {
                    return api.Customers.create(n)
                });

                Promise.all(newCustomerRequests)
                    .then((response) => {
                        if (isMounted.current) {
                            if (response && response.length > 0) {
                                const defaultRelationshipRequests = [];

                                for (let ri = 0; ri < response.length; ri++) {
                                    const tempItems = toJS(appointmentOffer.data.items);
                                    const ci = tempItems.findIndex(c => !c.customerId && c.customer.referenceId === response[ri].data.referenceId);
                                    const ui1 = relationshipUpdates.findIndex(u => u.party1Id === response[ri].data.referenceId);
                                    const ui2 = relationshipUpdates.findIndex(u => u.party2Id === response[ri].data.referenceId);
                                    const tempRelationshipUpdates = Array.from(new Set([...relationshipUpdates]));

                                    if (ri > 0) {
                                        defaultRelationshipRequests.push(
                                            api.CustomerRelationships.create({
                                                party1Id: response[0].data.id,
                                                party2Id: response[ri].data.id,
                                            })
                                        );
                                    }

                                    if (ci > -1) {
                                        tempItems[ci].customerId = response[ri].data.id;
                                        tempItems[ci].customer.id = response[ri].data.id;

                                        appointmentOffer.data.items = tempItems;
                                    }

                                    if (ui1 > -1) {
                                        tempRelationshipUpdates[ui1].party1Id = response[ri].data.id;
                                    }

                                    if (ui2 > -1) {
                                        tempRelationshipUpdates[ui2].party2Id = response[ri].data.id;
                                    }

                                    if (ui1 > -1 || ui2 > -1) {
                                        setRelationshipUpdates(tempRelationshipUpdates);
                                    }

                                    if (appointmentOffer.data.primaryContactId === response[ri].data.referenceId) {
                                        appointmentOffer.data.primaryContactId = response[ri].data.id
                                        appointmentOffer.data.primaryContact.id = response[ri].data.id
                                    }
                                }

                                if (defaultRelationshipRequests && defaultRelationshipRequests.length > 0) {
                                    Promise.all(defaultRelationshipRequests);
                                }
                            }

                            resolve();
                        }
                    })
            }
            else {
                resolve();
            }
        })
    }

    const handleCommitUpdate = (event, saveAppointmentOffer) => {
        setIsSaving(true);

        handleSaveNewCustomers(event)
            .then(() => {
                if (isMounted.current) {
                    const requests = [];
                    const relationshipOptions = Array.isArray(relationshipUpdates) ? JSON.parse(JSON.stringify(relationshipUpdates)) : [];

                    if (saveAppointmentOffer) {
                        requests.push(appointmentOffer.save(true));

                        // Create default relationships between everyone in this appointment.  It won't overwrite if there is existing relationship.
                        if (appointmentOffer.data.primaryContact) {
                            const primaryContact = appointmentOffer.data.primaryContact;

                            for (let c1 = 0; c1 < appointmentOffer.data.items.length; c1++) {
                                const party1 = appointmentOffer.data.items[c1].customer;

                                if (party1 && primaryContact.id !== party1.id) {
                                    // Check to make sure no manual updates have been made already
                                    if (!relationshipOptions.some(u1 => u1.party1Id === primaryContact.id && u1.party2Id === party1.id)) {
                                        relationshipOptions.push({
                                            party1Id: primaryContact.id,
                                            party2Id: party1.id,
                                            overwrite: false,
                                        })
                                    }
                                }

                                for (let p2 = 0; p2 < appointmentOffer.data.items.length; p2++) {
                                    const party2 = appointmentOffer.data.items[p2].customer;

                                    if (party1 && party2 && party1.id !== party2.id) {
                                        // Check to make sure no manual updates have been made already
                                        if (!relationshipOptions.some(u1 => u1.party1Id === party1.id && u1.party2Id === party2.id)) {
                                            relationshipOptions.push({
                                                party1Id: party1.id,
                                                party2Id: party2.id,
                                                overwrite: false,
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (relationshipOptions && relationshipOptions.length > 0) {
                        requests.push(api.Batch.relationships({ relationships: relationshipOptions }));
                    }

                    Promise.all(requests)
                        .then(response => {
                            if (isMounted.current) {
                                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                    const data = Array.isArray(response) && response[0] ? response[0] : (response && response.data ? response.data : null);
                                    props.onSuccess(event, { updated: saveAppointmentOffer, data: saveAppointmentOffer ? data : null });
                                }
                            }
                        })
                        .finally(() => {
                            setIsSaving(false);
                        })
                }
            })
    }

    const handleIneligibleConfirm = event => {
        appointmentOffer.checkConflicts(true)
            .then(conflicts => {
                if (isMounted.current) {
                    if (!conflicts) {
                        handleCommitUpdate(event, true);
                    } else {
                        showConfirmConflictsModal();
                    }
                }
            })

        confirmModalRef.current.close();
    }

    const handleIneligibleCancel = () => {
        confirmModalRef.current.close();
    }

    const handleConflictsConfirm = event => {
        handleCommitUpdate(event, true);
        confirmModalRef.current.close();
    }

    const handleConflictsCancel = () => {
        confirmModalRef.current.close();
    }

    const handleTimeslotRemove = (event, start, userId) => {
        if (start && userId) {
            const index = appointmentOffer.data.timeslots.findIndex(t => t.start.isSame(start) && t.userId === userId);

            if (index > -1) {
                appointmentOffer.data.timeslots.splice(index, 1);
                appointmentOffer.data.hasUnsavedChanges = true;
            }
        }
    }

    const handleNewNote = event => {
        const tempCustomer = { firstName: 'Everyone', notes: appointmentOffer.data.notes && appointmentOffer.data.notes.length > 0 ? toJS(appointmentOffer.data.notes) : [] };
        newNote.initialize(tempCustomer, 'appointmentOffer', 'appointmentOffer', null, false);
        quickDrawer.activateQuickDrawer('note', 'create', null, handleNewNoteSuccess);
    }

    const handleNewNoteSuccess = (result) => {
        if (result && result.updated) {
            const newNote = result.data;

            appointmentOffer.data.notes.push(newNote);
            appointmentOffer.hasUnsavedChanges = true;
        }
    }

    const renderCustomer = (customerId, customer, existingServices, index) => {
        return <div className='customer-selected d-flex'>
            <div className='flex-1'>
                <div
                    className='profile-wrapper'
                >
                    <div className='profile'>
                        <span
                            className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer)} fs-sm mr-2`}
                            title={ph.getFullName(customer)}
                        >
                            {`${customer.firstName[0]}${customer.lastName[0]}`.toUpperCase()}
                        </span>
                    </div>
                    <div className='description flex-1'>
                        {
                            !customerId ?
                                <div>{bh.renderAppointmentNew(null, 'badge-sm fs-sm mb-1')}</div> : null
                        }
                        <div className='d-flex'>
                            <div className='flex-1'>
                                <div
                                    className='text-gray-700'
                                    title={customer.dateOfBirth ? `DOB: ${moment(customer.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                >
                                    {ph.getFullName(customer, true)}
                                    {
                                        customer.dateOfBirth || customer.sex || customer.gender || customer.pronoun ?
                                            <small className='ml-2'>({`${ph.getAge(customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer)}`.trim()})</small> : null
                                    }
                                    {
                                        customer.dateOfBirth ?
                                            <div className='fs-sm info text-gray-700'><span className='text-nowrap mr-1'><strong className='mr-1'>DOB:</strong>{sys.getFormattedLongDate(customer.dateOfBirth)}</span></div> : null
                                    }
                                    {
                                        customer.patientProfile && customer.patientProfile.healthCardNumber ?
                                            <div className='fs-sm info text-gray-700'><span className='text-nowrap mr-1'><strong className='mr-1'>HC #:</strong>{ph.formatHealthCard(customer.patientProfile.healthCardNumber)}</span><small className={'text-nowrap'}>(Exp. {fn.formatDate(customer.patientProfile.healthCardExpiryDate, 'MMM Do, YYYY')})</small></div> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='fs-sm mt-2'>
                            <select
                                className='custom-select form-control'
                                placeholder='Relationship'
                                disabled={!appointmentOffer.data || !appointmentOffer.data.primaryContact || appointmentOffer.data.primaryContact.id === customer.id}
                                value={appointmentOffer.data && appointmentOffer.data.items && appointmentOffer.data.items[index].relationship ? appointmentOffer.data.items[index].relationship : ''}
                                onChange={(e) => { handleRelationshipChange(e, customer, index) }}
                            >
                                <option value=''>{!appointmentOffer.data || !appointmentOffer.data.primaryContact || appointmentOffer.data.primaryContact.id === customer.id ? 'Self' : 'Not confirmed'}</option>
                                <optgroup label='Common'>
                                    {
                                        ph.COMMON_RELATIONSHIP_TYPES.map((r, ri) => {
                                            return <option
                                                key={`customer_common_relationship_type_${customer.id}_${ri}`} value={r}>
                                                {
                                                    cache.getReferenceDataOptions('CustomerRelationshipType').some(o => o.key === r) ?
                                                        cache.getReferenceDataOptions('CustomerRelationshipType').filter(o => o.key === r)[0].value : r
                                                }
                                            </option>
                                        })
                                    }
                                </optgroup>
                                <optgroup label='Less common'>
                                    {
                                        cache.getReferenceDataOptions('CustomerRelationshipType').filter(option => !ph.COMMON_RELATIONSHIP_TYPES.some(o => o === option.key)).map((option, di) => {
                                            return <option key={`customer_less_common_relationship_type_${customer.id}_${di}`} value={option.key}>{option.value}</option>
                                        })
                                    }
                                </optgroup>
                            </select>
                            <div className='validate validate-required'>
                                <Multiselect
                                    allowCreate={false}
                                    data={renderServices()}
                                    valueField='id'
                                    textField='name'
                                    placeholder='Services'
                                    tagComponent={({ item }) => (
                                        <span
                                            className='tag'
                                            style={{
                                                backgroundColor: item.colorHexValue,
                                                borderColor: item.colorHexValue,
                                            }}
                                        >
                                            <strong>{item.code}</strong>
                                        </span>
                                    )}
                                    value={(existingServices && existingServices.length > 0 ? existingServices.map(s => { return s.id }) : [])}
                                    onChange={(tag, metadata) => { handleServiceSelection(index, tag, metadata) }}
                                />
                            </div>
                            {
                                index === (appointmentOffer.data.items.length - 1) ?
                                    <button
                                        type='button'
                                        className='btn btn-link px-0 pb-0'
                                        onClick={handleCustomerAdd}
                                    >Add another patient</button> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderTimeslots = () => {
        if (appointmentOffer.data.timeslots && appointmentOffer.data.timeslots.length > 0) {
            const sortedUserTimeslots = toJS(appointmentOffer.data.timeslots.slice().sort((a, b) => { return a.start.toDate().getTime() - b.start.toDate().getTime() }))
                .reduce((array, timeslot) => {
                    const index = array.findIndex(a => a.userId === timeslot.userId);
                    const conflicts = appointmentOffer.conflicts.filter(c =>
                        c.hasConflict &&
                        c.userId === timeslot.userId &&
                        moment(c.start).isSame(moment(timeslot.start)) &&
                        moment(c.end).isSame(moment(timeslot.start).add(appointmentOffer.data.duration, 'minutes'))
                    );
                    const conflict = conflicts && conflicts.length > 0 ? conflicts[0] : null;

                    if (index < 0) {
                        array.push({
                            userId: timeslot.userId,
                            user: uh.getById(timeslot.userId),
                            timeslots: [{
                                start: timeslot.start,
                                conflict: getAppointmentConflictMessage(conflict, timeslot.userId)
                            }]
                        });
                    }
                    else {
                        array[index].timeslots.push({
                            start: timeslot.start,
                            conflict: getAppointmentConflictMessage(conflict, timeslot.userId)
                        })
                    }

                    return array;
                }, []);

            return <div className='mb-2'>
                {
                    sortedUserTimeslots.map((t, ti) => {
                        return <div
                            key={`appointment-offer-timeslot-${ti}`}
                            className='timeslot-selected d-flex mb-2'
                        >
                            <div className='flex-1'>
                                <div
                                    className='profile-wrapper'
                                >
                                    <div className='profile'>
                                        <span
                                            className={`profile-image rounded-circle d-block fw-500` + (t.user && !t.user.profilePictureUri ? ` profile-initials bg-color${t.user.color}-500` : '')}
                                            style={t.user && t.user.profilePictureUri ? {
                                                backgroundImage: `url(${t.user.profilePictureUri})`,
                                                backgroundSize: 'cover',
                                            } : null}
                                            title={t.user ? t.user.fullName : 'System'}>
                                            {!t.user.profilePictureUri ? <div className='d-initials fs-xs'>{t.user.initials}</div> : null}
                                        </span>
                                    </div>
                                    <div className='description flex-1'>
                                        <span className='text-gray-700'>{t.user.fullName}</span>
                                        {
                                            t.timeslots.map((s, si) => {
                                                return <div
                                                    key={`appointment-offer-timeslot-${ti}-${si}`}
                                                    className='d-flex w-100 pt-1'
                                                >
                                                    <div className='flex-1'>
                                                        <div
                                                            className={'fs-sm info ' + (!!s.conflict ? 'text-warning fw-500 text-strike' : 'text-gray-700')}
                                                            title={s.conflict}
                                                        >
                                                            {fn.formatDate(s.start, 'ddd, MMM D, YYYY @ h:mm a')}
                                                        </div>
                                                    </div>
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon line-height-1'
                                                        title='Remove'
                                                        onClick={(e) => { handleTimeslotRemove(e, s.start, t.userId) }}
                                                    >
                                                        <i className='fal fa-times fs-lg text-danger'></i>
                                                    </button>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        }
    }

    const hasCustomerConflictMessage = () => {
        return appointmentOffer.conflicts.some(c => c.hasConflict && c.upcomingConflictCustomers && c.upcomingConflictCustomers.length > 0);
    }

    const getCustomerConflictMessage = () => {
        const messages = [];

        if (hasCustomerConflictMessage()) {
            for (let ci = 0; ci < appointmentOffer.conflicts.length; ci++) {
                if (appointmentOffer.conflicts[ci].upcomingConflictCustomers) {
                    for (let ui = 0; ui < appointmentOffer.conflicts[ci].upcomingConflictCustomers.length; ui++) {
                        const customer = appointmentOffer.conflicts[ci].upcomingConflictCustomers[ui];
                        if (!messages.some(m => m.id === customer.id)) {
                            messages.push({
                                id: customer.id,
                                message: `${ph.getPreferredFirstName(customer)} has an upcoming paid appointment.`
                            })
                        }
                    }
                }
            }
        }

        return messages;
    }

    const hasAppointmentConflictMessage = () => {
        return appointmentOffer.conflicts.some(c => c.hasConflict && (!c.upcomingConflictCustomers || c.upcomingConflictCustomers.length === 0));
    }

    const getAppointmentConflictMessage = (conflictData, userId) => {
        if (conflictData && userId) {
            if (conflictData.hasAppointmentConflict) {
                return `${uh.getDisplayShortNameById(userId)} has an appointment at the same time.`;
            }
            if (conflictData.hasScheduleConflict) {
                return `${uh.getDisplayShortNameById(userId)} is not scheduled to work at this time.`;
            }
            if (!conflictData.hasScheduleConflict && conflictData.hasBusinessDayConflict) {
                return `This is outside of business hours.`;
            }
            if (conflictData.hasBreakConflict) {
                return `${uh.getDisplayShortNameById(userId)} is scheduled to be on break at this time.`;
            }
            if (conflictData.hasTimeOffConflict) {
                return `${uh.getDisplayShortNameById(userId)} is scheduled to be away on this day.`;
            }
        }

        return null;
    }

    const renderServices = () => {
        const filteredServices = services.filter(s =>
            (!s.activeStartingDate || moment(s.activeStartingDate).isSameOrBefore(moment(appointmentOffer.data.start))));

        return filteredServices.map(s => {
            return {
                id: s.id,
                name: `${s.code} - ${s.name}`,
                code: s.code,
                colorHexValue: s.colorHexValue,
                duration: (s.defaultDurationInMinutes ? s.defaultDurationInMinutes : 0),
                isRepeatable: s.isRepeatable,
                userOverrides: s.userOverrides
            }
        })
    }

    const renderAddNoteButton = () => {
        return <>
            <button
                type='button'
                className={'btn btn-icon'}
                onClick={handleNewNote}
            >
                <i className='fal fa-comment-alt-lines'></i>
                {
                    appointmentOffer.data.notes && appointmentOffer.data.notes.length > 0 ?
                        <span className={`badge badge-icon`}>{appointmentOffer.data.notes.length}</span> : null
                }
            </button>
        </>
    }

    const refreshPrimaryContacts = () => {
        return new Promise((resolve) => {
            const customerIds = Array.from([...new Set(appointmentOffer.data.items.filter(c => c.customerId).map(c => { return c.customerId }))]);

            if (customerIds && customerIds.length > 0 && appointmentOffer.data.items.some(c => c.customer && c.customer.primaryContactPersonId)) {
                const primaryContactPersonIds = Array.from([...new Set(appointmentOffer.data.items.filter(c => c.customer && c.customer.primaryContactPersonId).map(c => { return c.customer.primaryContactPersonId }))]);
                customerIds.push(...primaryContactPersonIds);
            }

            if (customerIds && customerIds.length > 0) {
                api.CustomerRelationships.search({
                    parameters: [{
                        field: 'Party1Id',
                        value: customerIds.join(','),
                        operator: 'Contains'
                    }],
                    includeTotalCount: false,
                })
                    .then(({ data }) => {
                        if (isMounted.current) {
                            if (data && data.result && data.result.length > 0) {
                                setRelationships(data.result);
                                resolve(data.result);
                            }
                            else {
                                setRelationships([]);
                                resolve();
                            }
                        }
                    })
            }
            else {
                setRelationships([]);
                resolve();
            }
        })
    }

    const getPrimaryContactOptions = () => {
        let result = [];

        if (appointmentOffer.data.items.some(c => c.customer)) {
            for (let ci = 0; ci < appointmentOffer.data.items.length; ci++) {
                if (appointmentOffer.data.items[ci].customer && !result.some(r => r.id === appointmentOffer.data.items[ci].customer.id)) {
                    result.push(appointmentOffer.data.items[ci].customer);
                }
            }
        }

        if (relationships && relationships.length > 0) {
            for (let ri = 0; ri < relationships.length; ri++) {
                if (!result.some(r => r.id === relationships[ri].relatedTo.id)) {
                    result.push(relationships[ri].relatedTo);
                }
            }
        }

        if (appointmentOffer.data.primaryContact && !result.some(r => (
            r.id === appointmentOffer.data.primaryContact.id ||
            r.id === appointmentOffer.data.primaryContact.referenceId ||
            r.referenceId === appointmentOffer.data.primaryContact.referenceId
        ))) {
            appointmentOffer.data.primaryContact = null;
            appointmentOffer.data.primaryContactId = null;
        }

        return result;
    }

    const getSuggestionOptions = () => {
        let result = [];

        if (relationships && relationships.length > 0) {
            for (let ri = 0; ri < relationships.length; ri++) {
                if (!appointmentOffer.data.items.some(c => c.customer && c.customer.id === relationships[ri].relatedTo.id) &&
                    !result.some(r => r.id === relationships[ri].relatedTo.id)) {
                    result.push(relationships[ri].relatedTo);
                }
            }
        }

        return result;
    }

    return <>
        {
            (props.drawer === quickDrawer.drawerOpened) ?
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: event => {
                            if (!confirmModalRef.current || !confirmModalRef.current.isVisible) {
                                handleCancel(event)
                            }
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <Observer>{() =>
            <form ref={validateRef} onSubmit={handleSubmit}>
                <fieldset disabled={appointmentOffer.isSaving || isSaving}>
                    <div className='quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={'fal fa-calendar-star'}
                            action='Offer'
                            category='Appointment Time(s)'
                            className='appointments'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            {
                                isReady ?
                                    <FadeIn>
                                        <div className='new-timeslot-reply body-content'>
                                            {
                                                eligibles && eligibles.length > 0 ?
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                {
                                                                    mohReady === true ?
                                                                        <div className='alert alert-info p-3 mb-0' role='alert'>
                                                                            <strong className='d-block mb-2'>Information</strong>
                                                                            <ul className='pl-3 mb-0'>
                                                                                {
                                                                                    eligibles.map((i, ii) => {
                                                                                        return <li key={`appointment_offer_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {ph.getPreferredFirstName(i.customer)}'s eligibility confirmed.</li>
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div> :
                                                                        <>
                                                                            {
                                                                                mohReady === false ?
                                                                                    <div className={'alert alert-warning p-3 mb-0'} role='alert'>
                                                                                        <strong className='d-block mb-2'>Warning 1</strong>
                                                                                        <ul className='pl-3 mb-0'>
                                                                                            <li><strong className='text-warning-900'>{auth.currentTenant.publicInsuranceUnitId} verification has not been setup.</strong></li>
                                                                                            {
                                                                                                eligibles.filter(e => e.isAgeChecked).map((i, ii) => {
                                                                                                    return <li key={`appointment_offer_eligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {ph.getPreferredFirstName(i.customer)}'s age eligibility confirmed.</li>
                                                                                                })
                                                                                            }
                                                                                            {
                                                                                                hasCustomerConflictMessage() ?
                                                                                                    getCustomerConflictMessage().map((m, mi) => {
                                                                                                        return <li key={`appointment_offer_upcoming_${m.id}_${mi}`}>{m.message}</li>
                                                                                                    }) : null
                                                                                            }
                                                                                            {
                                                                                                hasAppointmentConflictMessage() ?
                                                                                                    <li>Some of the appointment times have conflicts.</li> : null
                                                                                            }
                                                                                        </ul>
                                                                                    </div> : null
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </section> : null
                                            }
                                            {
                                                (hasCustomerConflictMessage() || hasAppointmentConflictMessage() || (ineligibles && ineligibles.length > 0)) && mohReady !== false ?
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='alert alert-warning p-3 mb-0' role='alert'>
                                                                    <strong className='d-block mb-2'>Warning</strong>
                                                                    <ul className='pl-3 mb-0'>
                                                                        {
                                                                            ineligibles && ineligibles.length > 0 ?
                                                                                <>
                                                                                    {
                                                                                        ineligibles.map((i, ii) => {
                                                                                            return <li key={`appointment_offer_ineligible_${ii}`}>{bh.renderServiceCode(i.service, 'float-left mr-1')} {i.earliestEligibleDate ? <>{ph.getPreferredFirstName(i.customer)} is not eligible until <span className='tt-underline'>{moment(i.earliestEligibleDate).format('YYYY-MM-DD')}</span>.</> : (i.ineligibilityReason ? `${(!!i.ineligibilityCode ? `(Error: ${i.ineligibilityCode}) ` : '')} ${ph.getPreferredFirstName(i.customer)} - ${i.ineligibilityReason}` : `${ph.getPreferredFirstName(i.customer)} - Cannot verify.  Reason unknown.`)}</li>
                                                                                        })
                                                                                    }
                                                                                </> : null
                                                                        }
                                                                        {
                                                                            hasCustomerConflictMessage() ?
                                                                                getCustomerConflictMessage().map((m, mi) => {
                                                                                    return <li key={`appointment_offer_upcoming_${m.id}_${mi}`}>{m.message}</li>
                                                                                }) : null
                                                                        }
                                                                        {
                                                                            hasAppointmentConflictMessage() ?
                                                                                <li>Some of the appointment times have conflicts.</li> : null
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section> : null
                                            }
                                            <Observer>{() => <>
                                                {
                                                    appointmentOffer.data.items && appointmentOffer.data.items.some(c => !!c.customer) && appointmentOffer.data.primaryContact ?
                                                        <section className='primary-contact'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group mb-0 validate validate-required'>
                                                                        <label className='required' htmlFor='contact-input'><small>Primary Contact</small></label>
                                                                        <Observer>{() =>
                                                                            <DropdownList
                                                                                data={getPrimaryContactOptions()}
                                                                                valueField='id'
                                                                                value={appointmentOffer.data && appointmentOffer.data.primaryContactId ? appointmentOffer.data.primaryContactId : ''}
                                                                                itemComponent={({ item }) => (
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                    >
                                                                                        <div className='profile'>
                                                                                            <span
                                                                                                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(item)} fs-sm mr-2`}
                                                                                                title={ph.getFullName(item)}
                                                                                            >
                                                                                                {item.firstName && item.lastName ? `${item.firstName[0]}${item.lastName[0]}`.toUpperCase() : ''}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description'>
                                                                                            <div
                                                                                                title={item.dateOfBirth ? `DOB: ${moment(item.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                                                                            >
                                                                                                {ph.getFullName(item, true)}
                                                                                                {
                                                                                                    item.dateOfBirth || item.sex || item.gender || item.pronoun ?
                                                                                                        <small className='ml-2'>({`${ph.getAge(item.dateOfBirth)} ${ph.getSexGenderPronounDisplay(item)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                !item.emailAddress && !item.phoneNumber ?
                                                                                                    <div className='fs-base text-danger text-truncate text-truncate-xl'>No contact information on file.</div> : null
                                                                                            }
                                                                                            {
                                                                                                item.emailAddress ?
                                                                                                    <div className='fs-base text-info text-truncate text-truncate-xl'>{item.emailAddress}</div> : null
                                                                                            }
                                                                                            {
                                                                                                item.phoneNumber ?
                                                                                                    <div className='fs-base text-info text-truncate text-truncate-xl'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                valueComponent={({ item }) => (
                                                                                    item ?
                                                                                        <div
                                                                                            className='profile-wrapper py-o'
                                                                                        >
                                                                                            <div className='profile'>
                                                                                                <span
                                                                                                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(item)} fs-sm mr-2`}
                                                                                                    title={ph.getFullName(item)}
                                                                                                >
                                                                                                    {item.firstName && item.lastName ? `${item.firstName[0]}${item.lastName[0]}`.toUpperCase() : ''}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='description'>
                                                                                                <div
                                                                                                    className='text-gray-700'
                                                                                                    title={item.dateOfBirth ? `DOB: ${moment(item.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                                                                                >
                                                                                                    {ph.getFullName(item, true)}
                                                                                                    {
                                                                                                        item.dateOfBirth || item.sex || item.gender || item.pronoun ?
                                                                                                            <small className='ml-2 text-gray'>({`${ph.getAge(item.dateOfBirth)} ${ph.getSexGenderPronounDisplay(item)}`.trim()})</small> : null
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    !item.emailAddress && !item.phoneNumber ?
                                                                                                        <div className='fs-base text-danger text-truncate text-truncate-xl'>No contact information on file.</div> : null
                                                                                                }
                                                                                                {
                                                                                                    item.emailAddress ?
                                                                                                        <div className='fs-base text-info text-truncate text-truncate-xl'>{item.emailAddress}</div> : null
                                                                                                }
                                                                                                {
                                                                                                    item.phoneNumber ?
                                                                                                        <div className='fs-base text-info text-truncate text-truncate-xl'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</div> : null
                                                                                                }
                                                                                            </div>
                                                                                        </div> : null
                                                                                )}
                                                                                onChange={handlePrimaryContactChange}
                                                                            />
                                                                        }</Observer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : null
                                                }
                                            </>}</Observer>
                                            <Observer>{() =>
                                                appointmentOffer.data.items && appointmentOffer.data.items.length > 0 ?
                                                    appointmentOffer.data.items.map(({ customerId, customer, services }, ci) => {
                                                        return ci === 0 || appointmentOffer.data.primaryContact ?
                                                            <section
                                                                key={`timeslot-reply-customer-services-${ci}`}
                                                                className='customer'>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group mb-0'>
                                                                            <div className='d-flex align-items-center mb-1'>
                                                                                <label className={'flex-1 mb-0' + (ci < 2 ? ' required' : '')} htmlFor={`customer-input-${ci}`}><small>Patient #{(ci + 1)}</small></label>
                                                                                {
                                                                                    !customer ?
                                                                                        <>
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-link p-0'
                                                                                                onClick={handleNewCustomer}
                                                                                            >Add new customer</button>
                                                                                            {
                                                                                                ci > 1 ?
                                                                                                    <button
                                                                                                        type='button'
                                                                                                        className='btn btn-icon line-height-1'
                                                                                                        title='Remove'
                                                                                                        onClick={(e) => { handleCustomerRemove(e, ci) }}
                                                                                                    >
                                                                                                        <i className='fal fa-times fs-lg text-danger'></i>
                                                                                                    </button> : null
                                                                                            }
                                                                                        </> :
                                                                                        <>
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-icon line-height-1'
                                                                                                title='Change customer'
                                                                                                onClick={(e) => { handleCustomerRemove(e, ci) }}
                                                                                            >
                                                                                                <i className='fal fa-times fs-lg text-danger'></i>
                                                                                            </button>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                !customer ?
                                                                                    <>
                                                                                        <div className='validate validate-required'>
                                                                                            <div className='dropdown'>
                                                                                                <CustomerFilter
                                                                                                    id={`${APPOINTMENT_OFFER_CUSTOMER_INPUT_ID}${ci}`}
                                                                                                    ref={(element) => newTimeslotCustomerFilterRef.current[ci] = element}
                                                                                                    delay={500}
                                                                                                    onChange={(e, term) => { handleCustomerSearchChange(e, ci, term) }}
                                                                                                />
                                                                                                <ul className='dropdown-menu'>
                                                                                                    {
                                                                                                        (customerSearchResult && customerSearchResult.length > 0 ? customerSearchResult.slice(0, 5) : getSuggestionOptions())
                                                                                                            .map((s, si) => {
                                                                                                                return <li
                                                                                                                    key={`search_result_${ci}_${si}`}
                                                                                                                    className={'dropdown-menu-item'}>
                                                                                                                    <div
                                                                                                                        className='profile-wrapper'
                                                                                                                        onClick={() => handleCustomerSearchClick(ci, s)}
                                                                                                                    >
                                                                                                                        <div className='profile d-flex flex-column justify-content-center align-content-center'>
                                                                                                                            <span
                                                                                                                                className={`profile-image profile-image-md profile-initials rounded-circle fs-xs text-white ${ch.getProfileColor(s)}`}
                                                                                                                                title={ph.getPreferredFullName(s)}
                                                                                                                            >
                                                                                                                                {s.initials}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div className='description'>
                                                                                                                            <div
                                                                                                                                className='fw-500 text-gray-700'
                                                                                                                                title={s.dateOfBirth ? `DOB: ${moment(s.dateOfBirth).format('YYYY-MM-DD')}` : null}
                                                                                                                            >
                                                                                                                                {ph.getLastFirstName(s, true)}
                                                                                                                                {
                                                                                                                                    s.dateOfBirth || s.sex || s.gender || s.pronoun ?
                                                                                                                                        <small className='ml-2 text-gray'>({`${ph.getAge(s.dateOfBirth)} ${ph.getSexGenderPronounDisplay(s)}`.trim()})</small> : null
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                s.address && s.address.country ?
                                                                                                                                    <div className='fs-95 info text-truncate text-truncate-xl'>{s.address.fullLine}</div> : null
                                                                                                                            }
                                                                                                                            {
                                                                                                                                s.dateOfBirth ?
                                                                                                                                    <div className='fs-90 info text-truncate text-truncate-xl'>DOB: {fn.formatDate(s.dateOfBirth)}</div> : null
                                                                                                                            }
                                                                                                                            {
                                                                                                                                s.emailAddress ?
                                                                                                                                    <div className='fs-90 text-info text-truncate text-truncate-xl'>{s.emailAddress}</div> : null
                                                                                                                            }
                                                                                                                            {
                                                                                                                                s.primaryPhoneNumber ?
                                                                                                                                    <div className='fs-90 text-info text-truncate text-truncate-xl'>{sys.getFormattedPhoneNumber(s.primaryPhoneNumber.number)}</div> : null
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            })
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </> : null
                                                                            }
                                                                            {
                                                                                customer ?
                                                                                    <>
                                                                                        {renderCustomer(customerId, customer, services, ci)}
                                                                                    </> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section> : null
                                                    }) : null
                                            }
                                            </Observer>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-5'>
                                                        <Observer>{() =>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <label className='required' htmlFor='appointment-offer-duration'><small>Duration</small></label>
                                                                <div className='input-group'>
                                                                    <input
                                                                        id='appointment-offer-duration'
                                                                        type='number'
                                                                        className='form-control'
                                                                        min={5}
                                                                        step={5}
                                                                        value={(appointmentOffer.data.duration ? appointmentOffer.data.duration : '')}
                                                                        onChange={handleDurationChange}
                                                                        onBlur={handleDurationBlur}
                                                                    />
                                                                    <div className='input-group-append'>
                                                                        <span className='input-group-text'>min.</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }</Observer>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0 validate validate-required'>
                                                            <label className='required' htmlFor='appointment-offer-duration'><small>Appointment Time(s)</small></label>
                                                            <Observer>{() => <>{renderTimeslots()}</>}</Observer>                                                            <div>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-link p-0'
                                                                    disabled={!appointmentOffer.data.duration}
                                                                    onClick={handleEnterSelectionMode}
                                                                >Add timeslot(s)</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='form-group mb-0 '>
                                                            <label htmlFor='appointment-offer-question'><small>Question for patient to answer</small></label>
                                                            <Observer>{() =>
                                                                <input
                                                                    id='appointment-offer-question'
                                                                    type='text'
                                                                    className='form-control'
                                                                    maxLength='255'
                                                                    autoComplete='off'
                                                                    value={appointmentOffer.data.question ? appointmentOffer.data.question : ''}
                                                                    onChange={handleQuestionChange}
                                                                />
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </FadeIn> : renderQuickDrawerLoading()
                            }
                        </div>
                        <div className='quick-drawer-action pl-3'>
                            <div className='row'>
                                <div className='col-4'>
                                    <Observer>{() => renderAddNoteButton()}</Observer>
                                </div>
                                <div className='col-8'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='submit'
                                            className='btn btn-success'
                                            disabled={
                                                !appointmentOffer.data.timeslots ||
                                                appointmentOffer.data.timeslots.length === 0 ||
                                                !appointmentOffer.data.items ||
                                                appointmentOffer.data.items.length === 0
                                            }
                                        >Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        }</Observer>
        <BodyEnd>
            <ConfirmModal ref={confirmModalRef} />
        </BodyEnd>
    </>
}

export default NewAppointmentOffer;