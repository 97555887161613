import React, { useRef, useEffect, useState, useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import { useNavigate } from 'react-router-dom';

import BodyEnd from './BodyEnd';
import AddressBookModal from './AddressBookModal/_index';

import AuthStore from '../../../stores/AuthStore';

import * as rts from '../../../constants/routes';
import * as AccessTypes from '../../../constants/accessTypes';
// import * as ph from '../../../utilities/personHelper';
import * as ah from '../../../utilities/accessHelper';
import * as sys from '../../../utilities/systemHelper';

const ADMIN_MENU_BUTTON_ID = '__admin_menu_button__'
const ADMIN_MENU_ID = '__admin_menu__'

function NavbarAdminMenu() {
    const isMounted = useRef(true);
    const addressBookModalRef = useRef(null);
    const auth = useContext(AuthStore);
    const navigate = useNavigate();
    const [showAdminMenu, setShowAdminMenu] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleHideAdminMenuClick)

        return () => {
            isMounted.current = false;
            document.removeEventListener('click', handleHideAdminMenuClick);
        }
    }, []) // eslint-disable-line

    const handleAdminMenuButtonClick = () => {
        const newShowNotificationMenu = !showAdminMenu;
        setShowAdminMenu(newShowNotificationMenu);
    }

    const handleHideAdminMenuClick = (event) => {
        const adminMenuButton = document.querySelector(`#${ADMIN_MENU_BUTTON_ID}`);
        const adminMenu = document.querySelector(`#${ADMIN_MENU_ID}`);
        const isMenuVisible = adminMenu.offsetParent !== null;
        const isButton = event.target.id === ADMIN_MENU_BUTTON_ID || adminMenuButton.contains(event.target);
        const isMenu = event.target.id === ADMIN_MENU_ID || adminMenu.contains(event.target);
        const hideAdminMenu = isMenuVisible && !isButton && !isMenu;

        if (hideAdminMenu) {
            setShowAdminMenu(false);
        }
    }

    const handleUserManagementClick = (event) => {
        setShowAdminMenu(false);
        navigate(rts.UserManagement.Users);
    }

    const handlePublicInsuranceClick = (event) => {
        setShowAdminMenu(false);
        navigate(rts.PublicInsuranceBillingReport.Home);
    }

    const handleAddressBookClick = (event) => {
        setShowAdminMenu(false);
        addressBookModalRef.current.show();
    }

    const handleOnlineBookingClick = (event) => {
        setShowAdminMenu(false);
        navigate(rts.OnlineBookingSchedules.Home);
    }

    const handleSubscriptionClick = (event) => {
        setShowAdminMenu(false);
        navigate(rts.Subscription.Plans);
    }

    return useObserver(() => <>
        {
            !!showAdminMenu ?
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: () => {
                            setShowAdminMenu(false)
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <div className={'d-flex align-items-center justify-content-center ml-2 pt-h' + (showAdminMenu ? ' show' : '')}>
            <button
                id={ADMIN_MENU_BUTTON_ID}
                type='button'
                className='btn btn-icon header-icon'
                data-toggle="dropdown"
                title='Other features'
                onClick={handleAdminMenuButtonClick}
            >
                <i className='fas fa-th'></i>
            </button>
            <div id={ADMIN_MENU_ID} className={'notification-dropdown-menu dropdown-menu dropdown-xl' + (showAdminMenu ? ' mt-h show' : '')}>
                <ul className='app-list'>
                    {/* {
                        ah.check(AccessTypes.UPDATE_SYSTEM_SETTING) ?
                            <li>
                                <Link className='app-list-item hover-white'>
                                    <div
                                        className='profile-wrapper'
                                    >
                                        <div className='profile'>
                                            {
                                                auth.currentUser && auth.currentTenant && auth.currentTenant.badgePictureUri ?
                                                    <span
                                                        className={`profile-image rounded-circle d-flex text-white bg-color1-500 fw-500`}
                                                        style={{
                                                            backgroundImage: `url(${auth.currentTenant.badgePictureUri})`,
                                                            backgroundSize: 'cover',
                                                        }}
                                                        title={auth.currentTenant.displayName}
                                                    >
                                                    </span> :
                                                    <>
                                                        {
                                                            auth.currentUser && auth.currentTenant ?
                                                                <span
                                                                    className={`profile-image profile-initials rounded-circle d-flex text-white bg-color1-500 fw-500`}
                                                                    title={auth.currentTenant.displayName}
                                                                >
                                                                    {ph.getInitials(auth.currentTenant.displayName)}
                                                                </span> : null
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    <span className='app-list-name'>
                                        Account
                                    </span>
                                </Link>
                            </li> : null
                    } */}
                    {/* {
                        ah.check(AccessTypes.UPDATE_SYSTEM_SETTING) ?
                            <li>
                                <Link className='app-list-item hover-white'>
                                    <div className='icon-stack'>
                                        <i className='base base-7 icon-stack-3x color-info-500'></i>
                                        <i className='base base-7 icon-stack-2x color-info-800'></i>
                                        <i className='fal fa-store-alt icon-stack-1x color-white'></i>
                                    </div>
                                    <span className='app-list-name'>
                                        This Location
                                    </span>
                                </Link>
                            </li> : null
                    } */}
                    {/* {
                        ah.check(AccessTypes.UPDATE_SYSTEM_SETTING) ?
                            <li>
                                <Link to={`${rts.OrganizationManagement.Home}`} className='app-list-item hover-white'>
                                    <div className='icon-stack'>
                                        <i className='base base-14 icon-stack-3x color-warning-500'></i>
                                        <i className='base base-7 icon-stack-2x color-warning-800'></i>
                                        <i className='fal fa-building icon-stack-1x color-white'></i>
                                    </div>
                                    <span className='app-list-name'>
                                        Organizations
                                    </span>
                                </Link>
                            </li> : null
                    } */}
                    {
                        ah.check(AccessTypes.UPDATE_SYSTEM_SETTING) ?
                            <li>
                                <div className='app-list-item hover-white cursor-pointer' onClick={handleUserManagementClick}>
                                    <div className='icon-stack'>
                                        <i className='base base-14 icon-stack-3x color-warning-500'></i>
                                        <i className='base base-7 icon-stack-2x color-warning-800'></i>
                                        <i className='fal fa-users icon-stack-1x color-white'></i>
                                    </div>
                                    <span className='app-list-name'>
                                        Users
                                    </span>
                                </div>
                            </li> : null
                    }
                    {
                        ah.checkAny([AccessTypes.VIEW_PUBLIC_INSURANCE_BILLING_REPORT]) ?
                            <li>
                                <div className='app-list-item hover-white cursor-pointer' onClick={handlePublicInsuranceClick}>
                                    <span className='icon-stack'>
                                        <i className='base-2 icon-stack-3x color-success-700'></i>
                                        <i className='base-2 icon-stack-2x color-success-900'></i>
                                        <i className='fal fa-heart icon-stack-1x color-white'></i>
                                    </span>
                                    <span className='app-list-name'>
                                        {auth.currentTenant.publicInsuranceUnitId} Billing
                                    </span>
                                </div>
                            </li> : null
                    }
                    {
                        ah.check(AccessTypes.UPDATE_ONLINE_BOOKING_SCHEDULE) ?
                            <li>
                                <div className='app-list-item hover-white cursor-pointer' onClick={handleAddressBookClick}>
                                    <span className='icon-stack'>
                                        <i className='base-7 icon-stack-3x color-fusion-300'></i>
                                        <i className='base-4 icon-stack-2x color-fusion-500'></i>
                                        <i className='fal fa-address-book icon-stack-1x color-white'></i>
                                    </span>
                                    <span className='app-list-name'>
                                        Address Book
                                    </span>
                                </div>
                            </li> : null
                    }
                    {
                        sys.configuration.onlineBooking.isEnabled && ah.check(AccessTypes.UPDATE_ONLINE_BOOKING_SCHEDULE) ?
                            <li>
                                <div className='app-list-item hover-white cursor-pointer' onClick={handleOnlineBookingClick}>
                                    <span className='icon-stack'>
                                        <i className='base-4 icon-stack-3x color-success-500 fa-rotate-90'></i>
                                        <i className='base-4 icon-stack-2x color-success-700 fa-rotate-90'></i>
                                        <i className='fal fa-table icon-stack-1x color-white'></i>
                                    </span>
                                    <span className='app-list-name'>
                                        Online Booking
                                    </span>
                                </div>
                            </li> : null
                    }
                    {/* {
                        ah.check(AccessTypes.VIEW_REPORT) ?
                            <li>
                                <Link className='app-list-item hover-white'>
                                    <span className='icon-stack'>
                                        <i className='base-4 icon-stack-3x color-primary-500'></i>
                                        <i className='base-4 icon-stack-2x color-primary-700'></i>
                                        <i className='ni ni-graph icon-stack-1x text-white'></i>
                                    </span>
                                    <span className='app-list-name'>
                                        Reports
                                    </span>
                                </Link>
                            </li> : null
                    } */}
                    {
                        ah.check(AccessTypes.UPDATE_SUBSCRIPTION) ?
                            <li>
                                <div className='app-list-item hover-white cursor-pointer' onClick={handleSubscriptionClick}>
                                    <span className='icon-stack'>
                                        <i className='base-7 icon-stack-3x color-fusion-300'></i>
                                        <i className='base-4 icon-stack-2x color-fusion-500'></i>
                                        <i className='fal fa-money-bill icon-stack-1x color-white'></i>
                                    </span>
                                    <span className='app-list-name'>
                                        Subscription
                                    </span>
                                </div>
                            </li> : null
                    }
                </ul>
            </div>
        </div>
        <BodyEnd>
            {
                ah.checkAny([AccessTypes.VIEW_ADDRESS_BOOK, AccessTypes.UPDATE_ADDRESS_BOOK]) ?
                    <AddressBookModal ref={addressBookModalRef} /> : null
            }
        </BodyEnd>
    </>)
}

export default NavbarAdminMenu;