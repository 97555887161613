import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';
import moment, { isMoment } from 'moment';

import api from '../api';
import * as fn from '../utilities/_functions';

export class TimeslotScheduleCreate {
    userId = null;
    date = null;
    start = null;
    end = null;
    duration = null;
    bookingTypes = [];
    isReady = false;
    isSaving = false;
    isLoading = false;
    hasUnsavedChanges = false;
    cancelTimeslotScheduleUpdate = null;

    initialize = (userId, start) => {
        this.clear();
        this.userId = userId;
        this.date = (isMoment(start) ? start.clone() : moment(start)).startOf('day');
        this.start = (isMoment(start) ? start.clone() : moment(start)).format('HH:mm');
        this.isReady = true;

        return Promise.resolve();
    }

    save = (notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            if (!that.cancelTimeslotScheduleUpdate && that.hasUnsavedChanges) {
                api.TimeslotSchedules.create(
                    {
                        userId: that.userId,
                        start: `${moment(that.date).format('YYYY-MM-DD')}T${that.start}`,
                        end: `${moment(that.date).format('YYYY-MM-DD')}T${that.end}`,
                        bookingTypeIds: that.bookingTypes.map(a => { return a.id })
                    }
                    , (c) => { that.cancelTimeslotScheduleUpdate = c })
                    .then(() => {
                        that.hasUnsavedChanges = false;
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        that.cancelTimeslotScheduleUpdate = null;
                        that.isSaving = false;
                    })
            }
            else {
                resolve();
            }
        })
    }

    clear = () => {
        this.userId = null;
        this.date = null;
        this.start = null;
        this.end = null;
        this.duration = null;
        this.bookingTypes = [];
        this.isReady = false;
        this.isSaving = false;
        this.isLoading = false;
        this.hasUnsavedChanges = false;
        this.cancelTimeslotScheduleUpdate = null;

        if (fn.isFunction(this.cancelTimeslotScheduleUpdate)) {
            this.cancelTimeslotScheduleUpdate();
            this.cancelTimeslotScheduleUpdate = null;
        }
    }
}

decorate(TimeslotScheduleCreate, {
    userId: observable,
    date: observable,
    start: observable,
    end: observable,
    duration: observable,
    bookingTypes: observable,
    isReady: observable,
    isSaving: observable,
    isLoading: observable,
    hasUnsavedChanges: observable,
    initialize: action,
    refresh: action,
    save: action,
    clear: action,
})

export default createContext(new TimeslotScheduleCreate());