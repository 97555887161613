import { createContext } from 'react';
import { decorate, observable, action } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

const PAGE_SIZE = 25;

export class BookingSearchStore {
    items = [];
    total = null;
    isLoading = false;
    isSaving = false;
    isReady = false;

    cancelBookingSearch = null;

    initialize = async () => {
        this.clear();
        await this.refresh();
        this.isReady = true;
    }

    refresh = async (notify = true) => {
        const that = this;

        if (notify) {
            this.isLoading = true;
        }

        const { data } = await api.Bookings.search({
            parameters: [
                { field: 'Status', value: 'Pending' },
                { field: 'DeactivatedDateUtc', value: null }
            ],
            sortByFields: [
                { field: 'CreatedDateUtc', value: 'ASC' }
            ],
            offset: 0,
            limit: PAGE_SIZE,
            includeTotalCount: true,
        }, (c) => { that.cancelBookingSearch = c });

        action(e => {
            that.items = data.result && data.result.length > 0 ? data.result : [];
            that.total = data.total ? data.total : 0;
        })();

        if (notify) {
            this.isLoading = false;
        }
    }

    clear = () => {
        this.page = 1;
        this.items.clear();
        this.total = null;
        this.isLoading = false;
        this.isSaving = false;
        this.isReady = false;

        if (fn.isFunction(this.cancelBookingSearch)) {
            this.cancelBookingSearch();
            this.cancelBookingSearch = null;
        }
    }
}

decorate(BookingSearchStore, {
    items: observable,
    total: observable,
    isLoading: observable,
    isSaving: observable,
    isReady: observable,
    initialize: action,
    refresh: action,
    clear: action,
})

export default createContext(new BookingSearchStore());