import React, { useContext, useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';

import BodyEnd from '../../../../_shared/BodyEnd';
import ConfirmModal from '../../../../_shared/ConfirmModalComponent';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../../../_shared/QuickDrawerHeader';

import QuickDrawerStore from '../../../../../../stores/QuickDrawerStore';
import OrganizationCreateUpdateStore from '../../../../../../stores/OrganizationCreateUpdateStore';
import AuthStore from '../../../../../../stores/AuthStore';
import useSignalR from '../../../../../hooks/useSignalR';

import * as ErrorMessages from '../../../../../../constants/errorMessages';
import * as MaskKeys from '../../../../../../constants/maskKeys';
import * as fn from '../../../../../../utilities/_functions';
import * as oh from '../../../../../../utilities/operationHelper';
import * as ah from '../../../../../../utilities/accessHelper';

import './CreateUpdateOrganization.scss';
moment.locale('en');

function CreateUpdateOrganization(props) {
    const auth = useContext(AuthStore);
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const organization = useContext(OrganizationCreateUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [confirmDeleteOrganization, setConfirmDeleteOrganization] = useState(null);
    const [isAddressInformationActive, setIsAddressInformationActive] = useState(false);
    const [ready, setReady] = useState(false);
    const signalR = useSignalR();

    const hasFullAccess = ah.hasFullAccess(auth.currentUser.id);

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (organization.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (fn.validateForm(validateRef.current)) {
            try {
                const data = await organization.save(true);
                if (isMounted.current) {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, data);
                    }
                }
            }
            catch (e) {
                // The backend may return 409 if there's a name conflict
                window.alert(e.Message || e);
            }
        }
    }
    const handlePhoneNumberChange = (event) => {
        organization.data.phoneNumber = event.target.value;
        organization.hasUnsavedChanges = true;
    }
    const handleFaxNumberChange = (event) => {
        organization.data.faxNumber = event.target.value;
        organization.hasUnsavedChanges = true;
    }
    const handleEmailAddressChange = (event) => {
        organization.data.emailAddress = event.target.value;
        organization.hasUnsavedChanges = true;
    }
    const handleLegalNameChange = (event) => {
        organization.data.legalName = event.target.value;
        organization.hasUnsavedChanges = true;
    }
    const handleOperatingAsNameChange = (event) => {
        organization.data.operateAsName = event.target.value;
        organization.hasUnsavedChanges = true;
    }
    const handleAddressInformation = () => {
        setIsAddressInformationActive(true);
        quickDrawer.activateQuickDrawer('organization', 'address', null, handleAddressInformationUpdateSuccess, handleAddressInformationUpdateCancel);
    }

    const handleAddressInformationUpdateSuccess = (result) => {
        if (organization.data.id && result && result.updated) {
            toast.dark(() => <p data-addr-upd>Customer's address updated.</p>);
            // address updated. better be safe to clear all the temp data
            organization.clearNewAddress();
            organization.clearNewBillingAddress();
        }
        setIsAddressInformationActive(false);
    }

    const handleAddressInformationUpdateCancel = () => {
        setIsAddressInformationActive(false);
    }
    
    const loadData = async () => {
        const item = props.extraProps;
        await organization.initialize(item);
        setReady(true);
        quickDrawerFocus(props.drawer);
    }

    useEffect(() => {
        
        signalR.on('Organization', (updated) => {
            if (updated && organization && organization.id === updated.id) {
                organization.refresh();
            }
        });
        loadData();

        return () => {
            isMounted.current = false;
            organization.clear();
        }
    }, []) // eslint-disable-line

    const handleDeleteOrganization = (id) => {
        setConfirmDeleteOrganization(id);
    }

    const handleConfirmDeleteOrganizations = async (e) => {
        await organization.delete([{ id: confirmDeleteOrganization }]);
        if (isMounted.current) {
            setConfirmDeleteOrganization(null);
            if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                props.onSuccess(e);
            }
        }
    }

    const handleConfirmDeleteOrganizationCancel = () => {
        setConfirmDeleteOrganization(null);
    }

    return <>
        <Observer>{() =>
            ready &&
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
                <form ref={validateRef} onSubmit={handleSubmit}>
                    <fieldset disabled={organization.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('organization', 'new')}
                                action={props.extraProps ? 'Update' : 'Add new'}
                                category='Organization'
                                className='organizations'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    organization.isReady ?
                                        <FadeIn>
                                            <div className='update-organization body-content'>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-organization mb-0 validate validate-required'>
                                                                <label className='required'><small>Legal Name</small></label>
                                                                <input
                                                                    id='organization-name'
                                                                    type='text'
                                                                    className='form-control'
                                                                    maxLength='50'
                                                                    autoComplete='off'
                                                                    value={organization.data.legalName}
                                                                    onChange={handleLegalNameChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-organization mb-0 validate validate-required'>
                                                                <label className='required'><small>Operate As Name</small></label>
                                                                <input
                                                                    id='organization-name'
                                                                    type='text'
                                                                    className='form-control'
                                                                    maxLength='50'
                                                                    autoComplete='off'
                                                                    value={organization.data.operateAsName}
                                                                    onChange={handleOperatingAsNameChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-email'>
                                                                <label htmlFor='new-contact-primary-email'><small>Primary Email</small></label>
                                                                <MaskedInput
                                                                    id='new-contact-primary-email'
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    mask={MaskKeys.EMAIL_MASK}
                                                                    maxLength='150'
                                                                    autoComplete='off'
                                                                    value={organization.data.emailAddress ? organization.data.emailAddress : ''}
                                                                    onChange={handleEmailAddressChange}
                                                                // onBlur={checkConflicts}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-phone'>
                                                                <label htmlFor='new-contact-primary-phone'><small>Phone Number</small></label>
                                                                <MaskedInput
                                                                    id='new-contact-primary-phone'
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    mask={MaskKeys.PHONE_MASK}
                                                                    autoComplete='off'
                                                                    value={organization.data.phoneNumber || ''}
                                                                    onChange={handlePhoneNumberChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0 validate validate-phone'>
                                                                <label htmlFor='new-contact-primary-phone'><small>Fax Phone</small></label>
                                                                <MaskedInput
                                                                    id='new-contact-primary-phone'
                                                                    type='text'
                                                                    className='form-control'
                                                                    spellCheck={false}
                                                                    mask={MaskKeys.PHONE_MASK}
                                                                    autoComplete='off'
                                                                    value={organization.data.faxNumber || ''}
                                                                    onChange={handleFaxNumberChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <div className='quick-drawer-action'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='float-left'>
                                            <button
                                                type='button'
                                                className={'btn btn-icon' + (isAddressInformationActive ? ' text-primary' : '')}
                                                title='Address information'
                                                onClick={handleAddressInformation}
                                            >
                                                <i className={oh.getIcon('customer', 'address')}></i>
                                            </button>
                                        </div>
                                        <div className='float-right'>
                                            {
                                                hasFullAccess && props.extraProps &&
                                                <button
                                                    type='button'
                                                    className='btn btn-danger mr-4'
                                                    onClick={() => handleDeleteOrganization(props.extraProps.id)}
                                                >Delete</button>
                                            }
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel mr-2'
                                                onClick={handleCancel}
                                            >Cancel</button>
                                            <button
                                                type='submit'
                                                className='btn btn-success'
                                            >Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form >
                <BodyEnd>
                    <ConfirmModal
                        icon={<i className={'text-danger mr-2 ' + oh.getIcon('organization', 'delete')}></i>}
                        message={<>Continue to delete <span className='fw-500'>&nbsp;{organization.data ? organization.data.legalName : ''}</span>? </>}
                        descriptionClassName='warning-danger warning-flashing'
                        show={confirmDeleteOrganization}
                        onOption1Click={handleConfirmDeleteOrganizations}
                        onCancel={handleConfirmDeleteOrganizationCancel}
                    />
                </BodyEnd>
            </>
        }</Observer>
    </>
}

export default CreateUpdateOrganization;