import React from 'react';
import * as ph from './personHelper';

export const checkIndividualAppointmentIsValid = appointment => {
    return !appointment.isGroup && appointment.customer && (appointment.customer.defaultEmailAddressContact || appointment.customer.defaultMobileNumberContact);
}

export const checkGroupAppointmentIsValid = appointment => {
    return appointment.isGroup && appointment && appointment.primaryContact && (appointment.primaryContact.emailAddressContact || appointment.primaryContact.mobileNumberContact);
}

export const getIcon = (appointment, action) => {
    const isValid = checkIndividualAppointmentIsValid(appointment) || checkGroupAppointmentIsValid(appointment);

    if (isValid) {
        switch (action) {
            case 'GroupAppointmentBookedNew':
            case 'AppointmentBookedNew':
            case 'GroupAppointmentBooked':
            case 'AppointmentBooked':
                return <i className='fal fa-calendar-check text-appointment-500 mr-2'></i>

            case 'GroupAppointmentReminderNew':
            case 'AppointmentReminderNew':
            case 'GroupAppointmentReminder':
            case 'AppointmentReminder':
            case 'GroupAppointmentPrebookReminder':
            case 'AppointmentPrebookReminder':
                return <i className='fal fa-calendar-day text-appointment-500 mr-2'></i>
                
            default:
                break;
        }
    }
    return <i className='fal fa-exclamation-triangle text-danger mr-2'></i>
}

export const getMessage = (appointment, action) => {
    const isValid = checkIndividualAppointmentIsValid(appointment) || checkGroupAppointmentIsValid(appointment);
    let contact = appointment ? (appointment.customer && appointment.customer.primaryContactPerson ? appointment.customer.primaryContactPerson : appointment.customer) : '';
    let actionText = '';

    if (isValid) {
        switch (action) {
            case 'GroupAppointmentBookedNew':
            case 'GroupAppointmentBooked':
                actionText = 'Group Appointment Confirmation';
                contact = appointment && appointment.primaryContact ? appointment.primaryContact : appointment.customer;
                break;

            case 'GroupAppointmentReminderNew':
            case 'GroupAppointmentReminder':
                actionText = 'Group Appointment Reminder';
                contact = appointment && appointment.primaryContact ? appointment.primaryContact : appointment.customer;
                break;


            case 'GroupAppointmentPrebookReminder':
                actionText = 'Group Appointment Prebook Reminder';
                contact = appointment && appointment.primaryContact ? appointment.primaryContact : appointment.customer;
                break;

            case 'AppointmentBookedNew':
            case 'AppointmentBooked':
                actionText = 'Appointment Confirmation';
                break;

            case 'AppointmentPrebookReminder':
                actionText = 'Appointment Prebook Reminder';
                break;

            case 'AppointmentReminderNew':
            case 'AppointmentReminder':
                actionText = 'Appointment Reminder';
                break;

            default:
                break;
        }

        return <>Send&nbsp;<span data-send-confirmation-or-reminder className='fw-500'>{actionText}</span>&nbsp;to {(contact ? ph.getPreferredFirstName(contact) : '')}? </>
    }
    else {
        return <>No contact information found for {(contact ? ph.getPreferredFirstLastName(contact) : '')}</>
    }
}