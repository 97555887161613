// System determined access types
export const SYSTEM_CAN_PRESCRIBE = '__CanPrescribe';

// Basic access types
export const APPROVE_TIMEOFF = 'ApproveTimeOff';
export const APPROVE_TIME_ENTRY = 'ApproveTimeEntry';
export const UPDATE_ADDRESS_BOOK = 'UpdateAddressBook';
export const UPDATE_APPOINTMENT = 'UpdateAppointment';
export const UPDATE_BUSINESS_DAY = 'UpdateBusinessDay';
export const UPDATE_CUSTOMER = 'UpdateCustomer';
export const UPDATE_EXAM = 'UpdateExam';
export const UPDATE_EXAM_PRETEST = 'UpdateExamPretest';
export const UPDATE_MEDICAL_REPORT = 'UpdateMedicalReport';
export const UPDATE_ORGANIZATION = 'UpdateOrganization';
export const UPDATE_OTHER_EXAM = 'UpdateOtherExam';
export const UPDATE_OTHER_SCHEDULE = 'UpdateOtherSchedule';
export const UPDATE_OTHER_TASK = 'UpdateOtherTask';
export const UPDATE_PRODUCT = 'UpdateProduct';
export const UPDATE_PURCHASE = 'UpdatePurchase';
export const UPDATE_REFERRAL = 'UpdateReferral';
export const UPDATE_REFERRAL_APPOINTMENT = 'UpdateReferralAppointment';
export const UPDATE_SCHEDULE = 'UpdateSchedule';
export const UPDATE_ONLINE_BOOKING_SCHEDULE = 'UpdateOnlineBookingSchedule';
export const UPDATE_SERVICE = 'UpdateService';
export const UPDATE_SUBSCRIPTION = 'UpdateSubscription';
export const UPDATE_SYSTEM_SETTING = 'UpdateSystemSetting';
export const UPDATE_TENANT = 'UpdateTenant';
export const UPDATE_USER_GROUP = 'UpdateUserGroup';
export const UPDATE_WORK_ORDER = 'UpdateWorkOrder';
export const VIEW_ADDRESS_BOOK = 'ViewAddressBook';
export const VIEW_APPOINTMENT = 'ViewAppointment';
export const VIEW_COMMUNICATION_SUMMARY = 'ViewCommunicationSummary';
export const VIEW_CUSTOMER = 'ViewCustomer';
export const VIEW_EXAM = 'ViewExam';
export const VIEW_MEDICAL_REPORT_RESPONSE = 'ViewMedicalReportResponse';
export const VIEW_OTHER_EXAM = 'ViewOtherExam';
export const VIEW_OTHER_SCHEDULE = 'ViewOtherSchedule';
export const VIEW_OTHER_TASK = 'ViewOtherTask';
export const VIEW_PRODUCT = 'ViewProduct';
export const VIEW_PUBLIC_INSURANCE_BILLING_REPORT = 'ViewPubInsBillingReport';
export const VIEW_PURCHASE = 'ViewPurchase';
export const VIEW_REFERRAL_APPOINTMENT = 'ViewReferralAppointment';
export const VIEW_REPORT = 'ViewReport';
export const VIEW_SCHEDULE = 'ViewSchedule';
export const VIEW_ONLINE_BOOKING_SCHEDULE = 'ViewOnlineBookingSchedule';
export const VIEW_SERVICE = 'ViewService';
export const VIEW_USER_GROUP = 'ViewUserGroup';
export const VIEW_WORK_ORDER = 'ViewWorkOrder';

export const EXPORT_DAILY_INVOICES = 'ExportDailyInvoices';
export const EXPORT_DAILY_SALES = 'ExportDailySales';
export const EXPORT_DAILY_PAYMENTS = 'ExportDailyPayments';
export const EXPORT_INVOICES = 'ExportInvoices';
export const EXPORT_SALES = 'ExportSales';
export const EXPORT_PAYMENTS = 'ExportPayments';
export const EXPORT_CUSTOMERS = 'ExportCustomers';
export const EXPORT_PRODUCTS = 'ExportProducts';
export const EXPORT_PUBLIC_INSURANCE = 'ExportPublicInsurance';

export const VIEW_DAILY_INVOICE_SUMMARY = 'ViewDailyInvoiceSummary';
export const VIEW_DAILY_SALES_SUMMARY = 'ViewDailySalesSummary';
export const VIEW_DAILY_PAYMENT_SUMMARY = 'ViewDailyPaymentSummary';
export const VIEW_INVOICE_SUMMARY = 'ViewInvoiceSummary';
export const VIEW_SALES_SUMMARY = 'ViewSalesSummary';
export const VIEW_PAYMENT_SUMMARY = 'ViewPaymentSummary';