import React, { useContext, useEffect, useState, useRef } from 'react';
import { Observer } from 'mobx-react-lite';
import FadeIn from 'react-fade-in';
import momentLocalizer from 'react-widgets-moment';
import { GlobalHotKeys } from 'react-hotkeys';
import DateBox from 'devextreme-react/date-box';
import { Multiselect } from 'react-widgets'
import moment from 'moment';

import BodyEnd from '../../_shared/BodyEnd';
import ConfirmModal from '../../_shared/ConfirmModalComponent';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import TimeslotScheduleUpdateStore from '../../../../stores/TimeslotScheduleUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as uh from '../../../../utilities/userHelper';

import api from '../../../../api';

import './UpdateTimeslotSchedule.scss';

moment.locale('en');
momentLocalizer();

function UpdateTimeslotSchedule(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const confirmModalRef = useRef(null);
    const timeslotSchedule = useContext(TimeslotScheduleUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [bookingTypes, setBookingTypes] = useState([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        quickDrawerFocus(props.drawer);

        api.BookingTypes.all()
            .then(({ data }) => {
                if (isMounted.current) {
                    if (data) {
                        setBookingTypes(data);
                    }
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsReady(true);
                }
            })

        return () => {
            isMounted.current = false;
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (timeslotSchedule.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleTimeslotStartChange = element => {
        const value = element.value;
        const newStart = value ? moment(value) : null;

        timeslotSchedule.data.start = newStart ? newStart.format('HH:mm:ss') : null;
        timeslotSchedule.data.end = newStart && timeslotSchedule.data.duration ? newStart.clone().add(timeslotSchedule.data.duration, 'minutes').format('HH:mm:ss') : null;
        timeslotSchedule.hasUnsavedChanges = true;
    }

    const handleDurationChange = event => {
        timeslotSchedule.data.duration = parseInt(event.target.value, 10);
        timeslotSchedule.data.end = timeslotSchedule.data.start ? moment(timeslotSchedule.data.start, 'HH:mm:ss').add(timeslotSchedule.data.duration, 'minutes').format('HH:mm:ss') : null;
        timeslotSchedule.hasUnsavedChanges = true;
    }

    const handleBookingTypeChange = (tag, metadata) => {
        switch (metadata.action) {
            case 'insert':
                if (!timeslotSchedule.data.bookingTypes.some(a => a.id === metadata.dataItem.id)) {
                    timeslotSchedule.data.bookingTypes.push(metadata.dataItem);
                }
                break;

            case 'remove':
                const index = timeslotSchedule.data.bookingTypes.findIndex(a => a.id === metadata.dataItem.id);
                if (index !== -1) {
                    timeslotSchedule.data.bookingTypes.splice(index, 1);
                }
                break;

            default:
                break;
        }

        timeslotSchedule.hasUnsavedChanges = true;
    }

    const handleDelete = event => {
        confirmModalRef.current.show({
            icon: <i className='fal fa-calendar-times text-danger mr-2'></i>,
            message: <>Continue to delete the<span className='fw-500'>&nbsp;{moment(timeslotSchedule.data.start, 'HH:mm').format('h:mm a')} timeslot</span>? </>,
            option1ClassName: 'btn btn-danger shadow-0 bootbox-accept',
            option1Text: 'Delete',
            cancelText: 'Cancel',
            onOption1Click: handleDeleteConfirm,
            onCancel: handleDeleteCancel
        })
    }

    const handleDeleteConfirm = (event) => {
        timeslotSchedule.delete()
            .then(() => {
                if (isMounted.current) {
                    confirmModalRef.current.close();

                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: true, data: { id: timeslotSchedule.id } });
                    }
                }        
            })
    }

    const handleDeleteCancel = () => {
        confirmModalRef.current.close();
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            timeslotSchedule.save(true)
                .then(() => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true, data: { id: timeslotSchedule.id } });
                        }
                    }
                })
        }
    }

    const renderUser = () => {
        if (!timeslotSchedule.data) return;

        const user = uh.getById(timeslotSchedule.data.userId);

        return <div
            className='profile-wrapper pr-0'
        >
            <div className='profile'>
                <span
                    className={`profile-image rounded-circle d-block fw-500` + (user && !user.profilePictureUri ? ` profile-initials bg-color${user.color}-500` : '')}
                    style={user && user.profilePictureUri ? {
                        backgroundImage: `url(${user.profilePictureUri})`,
                        backgroundSize: 'cover',
                    } : null}
                    title={user ? user.fullName : 'System'}>
                    {user && !user.profilePictureUri ? <div className='d-initials'>{user.initials}</div> : null}
                </span>
            </div>
            <div className='description flex-1'>
                <div className='name'>{user.fullName}</div>
            </div>
        </div>
    }

    const getBookingTypes = () => {
        const filteredBookingTypes = bookingTypes.filter(a1 => timeslotSchedule.data.bookingTypes.filter(a2 => a2.id === a1.id).length === 0);
        return filteredBookingTypes;
    }

    return <>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <>
                    {
                        (props.drawer === quickDrawer.drawerOpened) ?
                            <GlobalHotKeys
                                keyMap={{
                                    close: ['esc'],
                                }}
                                handlers={{
                                    close: event => {
                                        handleCancel(event)
                                    },
                                }}
                                allowChanges={true}
                            /> : null
                    }
                    <fieldset disabled={timeslotSchedule.isSaving}>
                        <div className='quick-drawer'>
                            <QuickDrawerHeader
                                drawer={props.drawer}
                                icon={oh.getIcon('appointment', 'update')}
                                action='Update'
                                category='Online Booking Timeslot'
                                className='appointments'
                                onCancel={handleCancel}
                            />
                            <div className='quick-drawer-body'>
                                {
                                    timeslotSchedule.isReady && isReady ?
                                        <FadeIn>
                                            <div className='update-appointment body-content'>
                                                <section className='resource'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <label><small>With</small></label>
                                                                {renderUser()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <label className='required'><small>{timeslotSchedule.data.dayOfWeek}</small></label>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <div className='form-group mb-0 validate validate-required'>
                                                                <Observer>{() =>
                                                                    <DateBox
                                                                        id='update-timeslot-schedule-start'
                                                                        type='time'
                                                                        displayFormat={'hh:mm a'}
                                                                        min={moment('08:00', 'HH:mm').toDate()}
                                                                        max={moment('17:00', 'HH:mm').toDate()}
                                                                        useMaskBehavior={true}
                                                                        showClearButton={false}
                                                                        value={timeslotSchedule.data.start ? moment(timeslotSchedule.data.start, 'HH:mm:ss').toDate() : null}
                                                                        onValueChanged={handleTimeslotStartChange}
                                                                    >
                                                                    </DateBox>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Observer>{() =>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <div className='input-group'>
                                                                        <input
                                                                            id='update-timeslot-schedule-duration'
                                                                            type='number'
                                                                            className='form-control'
                                                                            min={5}
                                                                            step={5}
                                                                            value={(timeslotSchedule.data.duration ? timeslotSchedule.data.duration : '')}
                                                                            onChange={handleDurationChange}
                                                                        />
                                                                        <div className='input-group-append'>
                                                                            <span className='input-group-text'>min.</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }</Observer>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='services'>
                                                    <div className='row'>
                                                        <div className='col-12 mb-0'>
                                                            <div className='form-group validate validate-required'>
                                                                <label htmlFor='new-appointment-services' className='required'>
                                                                    <small>Accepts</small>
                                                                </label>
                                                                <Observer>{() =>
                                                                    <>
                                                                        <Multiselect
                                                                            allowCreate={false}
                                                                            data={getBookingTypes()}
                                                                            defaultValue={timeslotSchedule.data.bookingTypes}
                                                                            valueField='id'
                                                                            textField='name'
                                                                            tagComponent={({ item }) => (
                                                                                <span
                                                                                    className='tag'
                                                                                    style={{
                                                                                        backgroundColor: '#ff9999',
                                                                                        borderWidth: '1px',
                                                                                        borderStyle: 'solid',
                                                                                        borderColor: '#ff1f1f',
                                                                                        color: '#fff',
                                                                                    }}>
                                                                                    <strong>{item.name}</strong>
                                                                                </span>
                                                                            )}
                                                                            onChange={handleBookingTypeChange}
                                                                        />
                                                                    </>}</Observer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </div>
                            <Observer>{() =>
                                timeslotSchedule.isReady ?
                                    <div className='quick-drawer-action'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-danger mr-2'
                                                        onClick={handleDelete}
                                                    >Delete</button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-success'
                                                    >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }</Observer>
                        </div>
                    </fieldset>
                </>
            }</Observer>
        </form>
        <BodyEnd>
            <ConfirmModal ref={confirmModalRef} />
        </BodyEnd>
    </>
}

export default UpdateTimeslotSchedule;