import React, { useRef, useEffect, useContext, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { GlobalHotKeys } from 'react-hotkeys';
import { usePageVisibility } from 'react-page-visibility';
import moment from 'moment';

import BookingSearchStore from '../../../stores/BookingSearchStore';

import useSignalR from '../../hooks/useSignalR';

import * as fn from '../../../utilities/_functions';
import * as uh from '../../../utilities/userHelper';
import * as bh from '../../../utilities/badgeHelper';
import * as rts from '../../../constants/routes';

const ONLINE_BOOKING_BUTTON_ID = '__online_booking_button__';
const ONLINE_BOOKING_MENU_ID = '__online_booking_menu__';

function NavbarOnlineBooking() {
    const isMounted = useRef(true);
    const isPageVisible = useRef(null);
    const signalR = useSignalR();
    const focusTimer = useRef(null);
    const search = useContext(BookingSearchStore);
    const navigate = useNavigate();
    const pageVisibility = usePageVisibility();
    const [showMenu, setShowMenu] = useState(false);
    const [forceReload, setForceReload] = useState(false);

    useEffect(() => {
        loadData();

        document.addEventListener('click', handleHideOnlineBookingMenuClick);

        isPageVisible.current = pageVisibility;

        signalR.on('Booking', async (updated) => {
            if (updated) {
                if (isPageVisible.current) {
                    search.refresh();
                } else if (isMounted.current) {
                    setForceReload(true);
                }
            }
        });

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
            document.removeEventListener('click', handleHideOnlineBookingMenuClick);
        }

    }, []) // eslint-disable-line

    useEffect(() => {
        if (pageVisibility) {
            if (forceReload) {
                search.refresh()
                    .then(() => {
                        if (isMounted.current) {
                            setForceReload(false);
                        }
                    })
            }
        }
        isPageVisible.current = pageVisibility;
    }, [pageVisibility]) // eslint-disable-line

    const loadData = async () => {
        if (!search.isReady) {
            await search.initialize();
        }
        else {
            await search.refresh();
        }
    }

    const handleOnlineBookingButtonClick = () => {
        setShowMenu(!showMenu);
    }

    const handleHideOnlineBookingMenuClick = (event) => {
        const onlineBookingButton = document.querySelector(`#${ONLINE_BOOKING_BUTTON_ID}`);
        const onlineBookingMenu = document.querySelector(`#${ONLINE_BOOKING_MENU_ID}`);
        const isMenuVisible = onlineBookingMenu.offsetParent !== null;
        const isButton = event.target.id === ONLINE_BOOKING_BUTTON_ID || onlineBookingButton.contains(event.target);
        const isMenu = event.target.id === ONLINE_BOOKING_MENU_ID || onlineBookingMenu.contains(event.target);
        const hideNotificationMenu = isMenuVisible && !isButton && !isMenu;

        if (hideNotificationMenu) {
            setShowMenu(false);
        }
    }

    const handleViewBooking = (event, booking) => {
        const uri = `${rts.Appointments.Home}/day/${moment(booking.start).format('YYYY-MM-DD')}?bid=${booking.id}`;
        navigate(uri);

        if (isMounted.current) {
            setShowMenu(false);
        }
    }

    const renderItem = (booking) => {
        const user = uh.getById(booking.userId);

        return <div className='d-flex'>
            <span className='mr-2'>
                <span
                    className={'profile-image rounded-circle d-inline-block' + (user && !user.profilePictureUri ? ` profile-initials text-white bg-color${user.color}-500` : '')}
                    style={!user || user.profilePictureUri ? {
                        backgroundImage: `url(${(user && user.profilePictureUri ? user.profilePictureUri : '/media/img/default-genki.jpg')})`,
                        backgroundSize: 'cover',
                    } : null}
                    title={uh.getDisplayFullName(user)}
                >
                    {user && !user.profilePictureUri ? <div className='d-initials'>{user.initials}</div> : null}
                </span>
            </span>
            <div className='d-flex flex-column flex-1 ml-1'>
                <div className='cursor-pointer'>
                    <div className='d-flex'>
                        <div className='flex-1'>
                            <span className='name fs-lg fw-500'>
                                <span className='d-inline-block text-truncate text-truncate-md'>
                                    {booking.items[0].fullName}
                                </span>
                            </span>
                        </div>
                        <div>
                            {bh.renderBookingType(booking.items[0].bookingType)}
                        </div>
                    </div>
                    <span className='msg-a msg-truncate fs-sm html mt-n1'>
                        <p><strong className='text-gray-700'>{uh.getDisplayFullName(user)}</strong></p>
                        <p>{fn.formatShortDate(moment(booking.start), 'ddd, MMM D, YYYY @ h:mm a')}</p>
                    </span>
                </div>
                <div className='d-flex'>
                    <TimeAgo
                        className='fs-nano text-muted mt-h'
                        date={moment.utc(booking.createdDateUtc).local().toDate()}
                        title={moment.utc(booking.createdDateUtc).local().format('dddd MMMM D, YYYY h:mm a')} minPeriod={60}
                    />
                </div>
            </div>
        </div>
    }

    return useObserver(() => <>
        {
            !!showMenu ?
                <GlobalHotKeys
                    keyMap={{
                        close: ['esc'],
                    }}
                    handlers={{
                        close: () => {
                            setShowMenu(false)
                        },
                    }}
                    allowChanges={true}
                /> : null
        }
        <div className={'d-flex align-items-center justify-content-center ml-2 pt-h' + (showMenu ? ' show' : '')}>
            <button
                id={ONLINE_BOOKING_BUTTON_ID}
                type='button'
                className='btn btn-icon header-icon'
                data-toggle="dropdown"
                title='Pending Online Bookings'
                onClick={handleOnlineBookingButtonClick}
            >
                <i className='fal fa-phone-laptop'></i>
                {
                    search.total && search.total > 0 ?
                        <span className='badge badge-icon bg-success text-shadow-0 float-right mr-n1'>{search.total}</span> : null
                }
            </button>
            <div id={ONLINE_BOOKING_MENU_ID} className={'online-booking-dropdown-menu dropdown-menu dropdown-xl' + (showMenu ? ' mt-h show' : '')}>
                <ul className='notification h-100'>
                    {
                        search.isReady && search.items && search.items.length > 0 ?
                            <>
                                {
                                    search.items.map((b, bi) => {
                                        return <li
                                            key={`online_booking_${bi}`}
                                            className='online-booking-item'
                                            onClick={(e) => { handleViewBooking(e, b) }}
                                        >
                                            {renderItem(b)}
                                        </li>
                                    })
                                }
                            </> :
                            <li className='online-booking-item mb-0'>
                                <div className='d-flex flex-column justify-content-center' style={{ height: '68px' }}>
                                    <span className='text-gray-500'><em>No pending online booking found</em></span>
                                </div>
                            </li>
                    }
                </ul>
            </div>
        </div>
    </>)
}

export default NavbarOnlineBooking;